import React from 'react'
import Authordetails from '../components/Authordetails';


function AuthorDetails() {
  return (
    <div>


<Authordetails/>


    </div>
  )
}

export default AuthorDetails