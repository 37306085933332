import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';
// import { Link} from 'react-router-dom';
import '../style.css';
import Image from 'react-bootstrap/Image';
import Zoom from 'react-reveal/Zoom';

function Rahulverma() {
  return (
    <div>

<Container>

<Row className='mt-5'>
        <Col lg={12} md={12} sm={12}>
            <Zoom>
       <h1 className="one" style={{textAlign:'left',color:'#ff8664'}}><i>Author Overview</i></h1>
       </Zoom>
        </Col>
    </Row>

    <Row className="mb-5" style={{marginTop:'60px'}} >

        <Col sm={12} md={4} lg={4}>
        <Image className="frame img" src="../images/rahulverma.jpeg" width={'350px'} height={'350px'}/>
            <h3 className="text p-3"  style={{textAlign:'center'}}>    राहुल वर्मा  </h3>
        </Col>

        <Col sm={12} md={8} className="mt-2 mb-4 "> 
        {/* <h2 className="" style={{textAlign:'center',color:'black'}}>Author Overview</h2> */}
        <p className="mt-3" style={{textAlign:'justify'}}> छत्तीसगढ की माटी में पले-बढ़े राहुल वर्मा का जन्म 07 मार्च 1988 को संस्कारधानी बिलासपुर में हुआ। वे बचपन से ही प्रतिभाशाली थे। उन्होंने प्राथमिक, माध्यमिक एवं उच्चतर माध्यमिक शिक्षा <strong>आनर्स</strong> के साथ प्रथम श्रेणी में उत्तीर्ण की। स्नातक परीक्षा में उन्होंने विश्वविद्यालय की <strong>प्रावीण्य सूची</strong>  में स्थान प्राप्त किया। प्रदेश के प्रतिष्ठित केन्द्रीय विश्वविद्यालय- गुरूघासीदास विश्वविद्यालय से उन्होंने स्नातकोत्तर शिक्षा प्राप्त की और एम.काम. में वर्ष 2012 में विश्वविद्यालय में प्रथम स्थान प्राप्त  करते हुए <strong>गोल्ड मैडल</strong>  प्राप्त किया। वे महाविद्यालयीन शिक्षा के दरमियान बिलासपुर के प्रतिष्ठित सी.एम.डी. कालेज में  सत्र 2010 -11 में <strong>अध्यक्ष </strong> रहे जबकि इसी महाविद्यालय में सत्र 2009 - 10 में <strong>उपाध्यक्ष</strong>  भी रहे। <br/><br/>
        वर्ष 2016 -17 में वदान्या प्रकाशन की स्थापना हुई।  राहुल वर्मा वदान्या प्रकाशन के को-फाउन्डर एवं क्रिएटिव़ हेड हैं। निदा फ़ाज़ली साहब  के गज़ल की ये पंक्तियाँ उनके सौम्य व्यक्तित्व पर बिलकुल सटीक बैठती है...... सफर में धूप तो होगी, जो चल सको तो चलो । .......वदान्या प्रकाशन ने अनेक मानक ग्रंथों का प्रकाशन किया है जिनमें सुप्रसिद्ध  भाषाविद् डाॅ. विनय कुमार पाठक व डाॅ. विनोद कुमार वर्मा कृत ' छत्तीसगढी का सम्पूर्ण व्याकरण ' और ' हिन्दी का सम्पूर्ण व्याकरण '  पूरे देश भर में चर्चित है और ये दोनों ग्रंथ छत्तीसगढ के प्रतियोगी परीक्षा देने वाले अभ्यर्थियों में अत्यन्त लोकप्रिय है। राहुल वर्मा ' छत्तीसगढ सम्पूर्ण वस्तुनिष्ठ ' के सहलेखक भी  हैं जो छत्तीसगढ में चर्चित रही है।
    </p>
         
            </Col> 
    </Row>
</Container>


    </div>
  )
}

export default Rahulverma