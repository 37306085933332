import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';
// import { Link} from 'react-router-dom';
import '../style.css';
import Image from 'react-bootstrap/Image';
import Zoom from 'react-reveal/Zoom';
function vinodpathak() {
  return (
    <div>
<Container>

<Row className='mt-5'>
        <Col lg={12} md={12} sm={12}>
            <Zoom>
       <h1 className="one" style={{textAlign:'left',color:'#ff8664'}}><i>Author Overview</i></h1>
       </Zoom>
        </Col>
    </Row>

    <Row className="mb-5" style={{marginTop:'60px'}} >

        <Col sm={12} md={4} lg={4}>
        <Image className="frame img" src="../images/pathakji_bgr.png" width={'350px'} height={'380px'}/>
            <h3 className="text p-3"  style={{textAlign:'center'}}>    डॉ. विनय पाठक  </h3>
        </Col>

        <Col sm={12} md={8} className="mt-1 mb-4 "> 
        {/* <h2 className="" style={{textAlign:'center',color:'black'}}>Author Overview</h2> */}
        <p className="" style={{textAlign:'justify'}}> 
        {/* <strong> जन्म तिथि :-</strong>   11 जून 1946 बिलासपुर (छ.ग.)<br/>
           <strong>शिक्षा :-</strong> एम. ए. , पी-एच. डी. , डी. लिट. (हिंदी)<br/>
                                        पी-एच.डी. , डी. लिट. (भाषा विज्ञान)<br/> */}
          " 11 जून 1946 को बिलासपुर में जन्मे डॉ. पाठक ने हिंदी और भाषा विज्ञान में  पी.एच.डी और डि.लीट. की दोहरी उपाधि प्राप्त कर अकादमिक दृष्टि से अखिल भारतीय स्तर पर जहां कीर्तिमान स्थापित किया है, वहीं 63 शोधार्थियो ने आप के निर्देशन में पीएचडी की उपाधि अर्जित की है!
 छत्तीसगढ़ी भाषा साहित्य एवं संस्कृति में सर्वाधिक कार्य करके एवं करा कर आप छत्तीसगढ़ के जीवंत विश्वकोश के रूप में विश्व विख्यात है! छत्तीसगढ़ी की प्रतिष्ठित कवि छत्तीसगढ़ी शाहिद के प्रथम प्रमाणिक समीक्षक संस्मरण मत जीवनी लेखन व गद्य की प्रायः सभी विधाओं के शोधक के रूप में अपने छत्तीसगढ़ को राष्ट्रीय क्षितिज प्रदान किया है! 
 आपकी छत्तीसगढ़ी एवं हिंदी प्रदेय पर पी.एच.डी व डि.लीट. के एक दर्जन शोध कार्य संपन्न हो चुके हैं!<br/><br/>
 स्त्री, दलित, आदिवासी-जनजाति-विमर्श पर चर्चित होने के बाद अब विकलांग- विमर्श का प्रवर्तन आपको 21वी सदी का श्रेष्ठ समीक्षक सिद्ध करता है इसी क्रम में किन्नर-आलोचना के शिखर के रूप में दो ग्रंथों के पेड़ के साथ जनवादी का भी काव्य-विमर्श का सूत्रपात करके अब कृषक-विमर्श और वृद्ध-विमर्श की दशा और दिशा को मूर्त रूप प्रदान कर रहे हैं गीत-समीक्षा एवं और व्यंग्य लोचन के साथ भाषा-विज्ञान और लोक-साहित्य पर भी आपने महत्वपूर्ण कार्य किया है विमर्श के रूप में विख्यात डॉक्टर पाठक देश दुनिया के एक ऐसे प्रथम समीक्षक हैं जिन्होंने प्राया महत्वपूर्ण विमर्शौ पर नवीन स्थापना दी है!
 तात्पर्य यह है कि आलोचक के रूप में डॉक्टर पाठक पर जितने कार्य हो रहे हैं !आज तक पर देश के किसी भी समीक्षक पर नहीं हुए हैं अनेक राष्ट्रीय सम्मानो से अलंकृत राष्ट्रीय-अंतरराष्ट्रीय संगोष्ठियो मे सम्मानित आप एक आदर्श प्राध्यापक, समर्थ, साहित्यकार तथा राज्य भाषा व राष्ट्रभाषा के सेतु-पुरुष के रूप में पांचदशकों से अधिक काल तक छत्तीसगढ़ी और हिंदी की समर्पित साधना में शतत संलग्न रहे हैं! छत्तीसगढ़ी और हिंदी में 3 दर्जन से अधिक कृतियो से समीक्षक, शोधक, संपादक, युग-प्रवर्तक के रूप में आपने  एक पीढ़ी का मार्गदर्शन किया है! पूर्व में आप छत्तीसगढ़ राज्य भाषा आयोग रायपुर के अध्यक्ष रह चुके हैं! " <br/>
 
 
    </p>
         
            </Col> 
    </Row>
</Container>
<Container>
  <Row>
    <Col sm={12} md={4} lg={4}>
    <h4 style={{marginTop:'-10px',color:'#F65D4E'}}><strong>प्रकाशित कृतिया:</strong></h4>
<p style={{textAlign:'justify'}}>
  <ul>
    <li>छत्तीसगढ़ी का संपूर्ण व्याकरण </li>
    <li>हिंदी का संपूर्ण व्याकरण </li>
    <li>विकलांग - विमर्श की कहानियां</li>
    <li>समकालीन विमर्श के बदलते प्रतिमान</li>
    <li>विकलांग - विमर्श दशा और दिशा</li>
    <li>किन्नर - विमर्श दशा और दिशा</li>
    <li>हिंदी साहित्य को डॉ विनय कुमार पाठक का प्रदेय</li>
  </ul>
</p>
    </Col>

    <Col sm={12} md={8} lg={8}>
    <h4 style={{color:'#F65D4E'}}><strong>सम्मान / अभिनंदन</strong></h4>
<p style={{textAlign:'justify'}}>
  <ul>
    <li>सहस्त्राब्दी विश्व हिंदी सम्मेलन नई दिल्ली के इंटरनेशनल सभागार में 18 सितंबर 2000 को सहस्त्राब्दी हिंदी सेवी राष्ट्रीय सम्मान |</li>
    <li>वाग्विभूति संस्थान अमलनेर महाराष्ट्र द्वारा 6 अगस्त 2017 को जीवन साधना -पुरस्कार से सम्मानित |</li>
    <li>अखिल भारतीय साहित्य कला - मंच मुरादाबाद उत्तर प्रदेश द्वारा 30 दिसंबर 2007 को सतीश चंद्र अग्रवाल - स्मृति समीक्षा का राष्ट्रीय सम्मान |</li>
    <li>अंतर्राष्ट्रीय हिंदी सम्मेलन (18 20 जनवरी 2008 )जमशेदपुर ( झारखंड )में विशिष्ट वक्ता का सम्मान |</li>
    <li>अंतर्राष्ट्रीय हिंदी समारोह काठमांडू ( नेपाल )में 9 जून 2013 को विद्यावाचस्पति अलंकरण |</li>
    <li>विक्रमशिला हिंदी विद्यापीठ भागलपुर द्वारा 13 दिसंबर 2014 को "भारत-गौरव' सारस्वत सम्मान |</li>
    <li>देवगढ़ महोत्सव के अंतर्गत लखनऊ में हुए गरिमामय समारोह में भाषाविज्ञान का प्रभाश्री राष्ट्रीय सम्मान 18 मार्च 2018 को प्रदत |</li>
    <li>'कादंबरी' जबलपुर मध्य प्रदेश द्वारा 2 मार्च 2019 को पंडित ओंकार प्रसाद दुबे "वांग्मय-रत्न" सम्मान |</li>
    <li>मनमुक्त मानव मेमोरियल ट्रस्ट नारनौल हरियाणा द्वारा "डॉक्टर मनुमुक्त मानव शिखर - सम्मान" 26 मई 2019 |</li>
  </ul>
</p>
    </Col>

  </Row>

</Container>
    </div>
  )
}

export default vinodpathak