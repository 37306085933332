import React from 'react'
//import AllBooks from '../components/AllBooks'
import Somebooks from '../components/Somebooks'

function Books() {
  return (
    <div>

{/* <AllBooks/> */}
<Somebooks/>

    </div>
  )
}

export default Books