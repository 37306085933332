import React, { useState } from'react';
import Carousel from 'react-bootstrap/Carousel';
//import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
//import { Container } from 'react-bootstrap';
function IndividualIntervalsExample() {


  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    // <Carousel>
    //   <Carousel.Item interval={1000}>
    //     <img
    //       className="d-block w-100 img-fluid"
    //       src="./images/book2r.png"
    //       alt="First slide" style={{height:'600px'}}  //height :500px
    //     />
    
    //   </Carousel.Item>
    //   <Carousel.Item interval={500}>
    //     <img
    //       className="d-block w-100 img-fluid"
    //       src="./images/marge6.png"
    //       alt="Second slide" style={{height:'600px'}}   //height:500px
    //     />
   
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img
    //       className="d-block w-100 img-fluid"
    //       src="./images/marge5.png"
    //       alt="Third slide" style={{height:'600px'}}  //height : 500px
    //     />
       
    //   </Carousel.Item>
    // </Carousel>

    <Carousel activeIndex={index} onSelect={handleSelect}>
    <Carousel.Item className='firstslider'>
    <Row id='home'>
    <Col lg={6} md={6} sm={6} >
          <div className='mb-5 '>
          <img className=" mt-3 p-5 img-fluid img-slider" src="../images/front 1.jpg" alt="First slide" 
        style={{height:'550px',width:'500px',display:'flex'}}/>
          </div>
      
      </Col>
      <Col lg={6} md={6} sm={6}>
      <div  className='textslider p-4'>
         <Zoom> <h3 className='text_slider_heading text-center'>छत्तीसगढ़ी का सम्पूर्ण व्याकरण </h3></Zoom>
         <Fade bottom><p className='text-center text_slider mt-4'>छत्तीसगढ राजभाषा आयोग के सहयोग से 2018 के उत्तरार्ध में प्रकाशित अकादमिक दृष्टिकोण से एक महत्वपूर्ण मानक ग्रंथ </p>   </Fade>
          </div>
      </Col>
     
    </Row>
      {/* <img
        className="d-block w-100"
        src="holder.js/800x400?text=First slide&bg=373940"
        alt="First slide"
      /> */}
      {/* <Carousel.Caption>
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
    </Carousel.Item>
    <Carousel.Item className='firstslider'>

    <Row>
    <Col lg={6} md={6} sm={6}>
          <div className='mb-5 '>
          <img className=" mt-3 p-5 img-fluid img-slider" src="../images/front.jpg" alt="First slide" 
        style={{height:'550px',width:'500px',display:'flex'}}/>
          </div>
      
      </Col>
      <Col lg={6} md={6} sm={6}>
      <div  className='textslider p-4'>
         <Zoom> <h3 className='text_slider_heading text-center'>हिंदी का सम्पूर्ण व्याकरण </h3></Zoom>
         <Fade bottom><p className='text-center text_slider mt-4'>शिक्षा-संबंधी राष्ट्रीय नीति-संकल्प के अनुरूप विश्वविद्यालयों में उच्चतम स्तर के शिक्षा के मानदण्डों के अनुसार छत्तीसगढ शासन के संस्कृति विभाग के सहयोग से वदान्या प्रकाशन द्वारा प्रकाशित </p>   </Fade>
          </div>
      </Col>
    
    </Row>
  
    </Carousel.Item>
    <Carousel.Item className='firstslider'>
    <Row>
    <Col lg={6} md={6} sm={6}>
          <div className='mb-5  '>
          <img className=" mt-3 p-5 img-fluid img-slider" src="../images/cgexplain.jpeg" alt="First slide" 
        style={{height:'550px',width:'500px',display:'flex'}}/>
          </div>
      
      </Col>
      <Col lg={6} md={6} sm={6}>
      <div  className='textslider p-4'>
         <Zoom> <h3 className='text_slider_heading text-center'>छत्तीसगढ़ सम्पूर्ण वस्तुनिष्ठ </h3></Zoom>
         <Fade bottom><p className='text-center text_slider mt-4'>छत्तीसगढ़ की सम्पूर्ण ऐतिहासिक, सांस्कृतिक, भौगोलिक, राजनीतिक व प्रशासनिक, आर्थिक व सामाजिक व्यवस्था, छत्तीसगढ़ी भाषा व बोलियों आदि के संदर्भ में विगत वर्षों की प्रतियोगी परीक्षाओं में पूछे गये प्रश्नों का समाविष्ट </p>   </Fade>
          </div>
      </Col>
    
    </Row>
    </Carousel.Item>
  </Carousel>
  
  );
}

export default IndividualIntervalsExample;