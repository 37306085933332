import React from 'react'
import '../style.css';
import Bestselling from '../components/Bestselling'
import Slider from '../components/Slider';
import Dealofweek from '../components/Dealofweek';
//import Popularbook from './components/Popularbook';
 import PopularAuthors from '../components/PopularAuthors';
 //import Genres from '../components/Genres';
function Homepage() {
  return (
    <div>

    <Slider/>
    <Bestselling/>
    <Dealofweek/>
    {/* <Popularbook/> */}
    {/* <Genres/> */}
    <PopularAuthors/>
  
    </div>
  )
}

export default Homepage