import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
// import { Chip } from '@mui/material';
import '../style.css';

function Cgmankikaran() {
  return (
    <div>
<Container>
  <Row className="mt-5 mb-3 ">
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/cg_margdarshika.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col sm={12} md={8} className='text-BD'> 
<h2 className=" " style={{textAlign:'left'}}>छत्तीसगढी का मानकीकरण : मार्गदर्शिका</h2>
<p> By <span>डॉ. विनोद कुमार वर्मा<br/>( मुख्य संपादक एवं संयोजक संगोष्ठी )</span></p>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1' className='link2'>Purchase Now</a></Button>{' '} */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<p style={{textAlign:'justify'}}> छत्तीसगढी के मानकीकरण के लिए 22 जुलाई 2018 को बिलासपुर में आयोजित राज्यस्तरीय संगोष्ठी में राज्य के विभिन्न जिलों से आये शताधिक साहित्यकारों ने भाग लिया। छत्तीसगढ राजभाषा आयोग के अध्यक्ष डाॅ विनय कुमार पाठक के मुख्य आतिथ्य एवं पद्मश्री डाॅ सुरेन्द्र दुबे ( सचिव, छत्तीसगढ राजभाषा आयोग ) की अध्यक्षता में आयोजित इस संगोष्ठी में छत्तीसगढी के मानकीकरण के लिए अत्यन्त महत्वपूर्ण निर्णय लिए गये। यह संगोष्ठी मुख्य रूप से<strong>' छत्तीसगढी भाषा और देवनागरी लिपि '</strong>  एवं <strong>' छत्तीसगढी गद्य साहित्य में अपभ्रंश लेखन का दुष्प्रभाव '</strong> पर केन्द्रित था।<br/>
संगोष्ठी में विद्वान विषय विशेषज्ञ वक्ताओं द्वारा छत्तीसगढी भाषा और देवनागरी लिपि पर गहन विचार-विमर्श के बाद भाषाविद् डाॅ चित्तरंजन कर द्वारा प्रस्तुत छत्तीसगढी भाषा के मानकीकरण के लिए देवनागरी लिपि ( उसके 52 वर्ण ) की स्वीकार्यता बाबत् प्रस्ताव सर्वसम्मति से पारित किया गया।</p>
  <h4 style={{color:'#F65D4E'}}><strong> प्रस्ताव का प्रारूप -</strong> </h4>
  <p style={{textAlign:'justify'}}> छत्तीसगढी के मानकीकरण के लिए छत्तीसगढ राजभाषा आयोग द्वारा 22 जुलाई 2018 को बिलासपुर में आयोजित राज्यस्तरीय संगोष्ठी में यह प्रस्ताव पारित किया जाता है-</p>
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Paperback</h6>

    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>
</Container>

<Container>
    <Row>
        <Col sm={12} md={12} lg={12}>
            {/* <h4 style={{color:'#F65D4E'}}><strong>संदेश -</strong></h4> */}
<p style={{textAlign:'justify'}}>  <strong> छत्तीसगढ के राज्यपाल द्वारा 11 जुलाई 2008 को अधिसूचित छत्तीसगढ राजभाषा ( संशोधन ) अधिनियम 2007  ( धारा 2 ) के संशोधन के अनुरूप छत्तीसगढी भाषा के मानकीकरण के लिए देवनागरी लिपि ( उसके 52 वर्ण ) को यथारूप अंगीकृत किया जायेगा जिसे केन्द्र शासन ने हिन्दी भाषा के लिये अंगीकृत किया है।</strong>  </p>
       
       {/* <h4 style={{color:'#F65D4E'}}><strong>पाठकीय अभिमत</strong> </h4> */}
       
       <p style={{textAlign:'justify'}}>संगोष्ठी के प्रस्ताव, अभिमत पत्र, व्याख्यान आदि के संकलन व संपादन का दायित्व संगोष्ठी के संयोजक डाॅ विनोद कुमार वर्मा को दिया गया। तदोपरान्त 05 अक्टूवर 2018 को छत्तीसगढ राजभाषा आयोग की बैठक में इसके प्रकाशन का प्रस्ताव पारित किया गया। अंततोगत्वा छत्तीसगढ राजभाषा आयोग द्वारा इसे ग्रंथाकार रूप में <strong>' छत्तीसगढी का मानकीकरण : मार्गदर्शिका '</strong>  के नाम से दो भाषाओं - <strong>छत्तीसगढी</strong>  और <strong>हिन्दी</strong> में प्रकाशित कर 28 नवंबर 2022 को विमोचित किया गया। <strong>" वस्तुतः राज्यस्तरीय संगोष्ठी के चार वर्षों बाद छत्तीसगढ राजभाषा द्वारा दो भाषाओं में प्रकाशित संगोष्ठी की सम्पूर्ण विवरणीयुक्त यह ग्रंथ छत्तीसगढी भाषा के लिए देवनागरी लिपि के 52 वर्णों की स्वीकार्यता का प्रमाणित दस्तावेज के साथ छत्तीसगढी लेखन में बदलाव का आधिकारिक प्रस्थान बिन्दु भी है।"</strong> </p>
    
       
        </Col>
    </Row>
</Container>
    </div>
  )
}

export default Cgmankikaran