import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Button from 'react-bootstrap/Button';
//import Image from 'react-bootstrap/Image';
import '../style.css';
import { Link} from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
function Dealofweek() {
  return (
    <div>
{/* <Container className='mt-5 mb-5'>
<Row>
    <Col md={5}>
        <Zoom>
    <h1 className='heading1'>Deals Of The Week </h1>
    </Zoom>
    </Col>
    <Col><Divider  className='divider mt-4 d-none d-sm-block' style={{}} />
    </Col>
    <Col md={3}>
    <Zoom>
    <Button variant="danger" className='c-btn'> <a href='/#/Books' className='link2'>View All</a></Button>{' '}
    </Zoom>
    </Col>
</Row>
</Container>
<Container>
<Row>
    <Col md={7} sm={12} className="" style={{ borderRadius:'25px'}}>
    <img class="img-fluid bgimg" src="./images/posters.png" alt="Card image cap" />

    </Col>
    <Col md={5} sm={12}>
    <div class=" mb-4 p-2">
    <Fade bottom>
                <div  className='sidecard card-horizontal mt-4'>
                    <div class="img-square-wrapper">
                        <a href="//www.amazon.in/CHHATTISGARH-SAMPURNA-VYAKARAN-Vinod-Kumar/dp/8193253418/ref=sr_1_2?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-2"><img class="img-fluid img-week_deal " src="./images/front 1.jpg" alt="Card image cap"  /></a>
                 
                    </div>
                    <div class="card-body p-2">
                        <a href="//www.amazon.in/CHHATTISGARH-SAMPURNA-VYAKARAN-Vinod-Kumar/dp/8193253418/ref=sr_1_2?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-2" className='link'> <h4 class="card-title">छत्तीसगढ़ी का संपूर्ण व्याकरण</h4></a>
                    
                    <Link to="/Authors" className='link'>  <p class="card-text">डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</p></Link>
                    <span>₹300</span>
                    </div>
                </div>
                </Fade>
                <Divider  className='divider mt-4' style={{}} />
                <Fade bottom>
                <div  className='sidecard card-horizontal mt-3'>
                    <div class="img-square-wrapper">
                    <Link to="/Books">  <img class=" img-fluid img-week_deal " src="./images/Machhuware.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-2">
                    <Link to="/Books" className='link'><h4 class="card-title">मछुआरे की लड़की</h4></Link>
                    <Link to="/AuthorDetails" className='link'>  <p class="card-text">डॉ. विनोद कुमार वर्मा</p></Link>
                    <span>₹300</span>
                    </div>
                </div>
                </Fade>
                <Divider  className='divider mt-4' style={{}} />
                <Fade bottom>
                <div  className='sidecard card-horizontal mt-3'>
                    <div class="img-square-wrapper">
                  
                   <a href="//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1"><img class="img-fluid img-week_deal" src="./images/front.jpg" alt="Card image cap"  /></a>
                    </div>
                    <div class="card-body p-2">
                        <a href="//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1" className='link'><h4 class="card-title">हिंदी का संपूर्ण व्याकरण</h4></a>
                   
                    <Link to="/Authors" className='link'>   <p class="card-text">डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</p></Link>
                    <span>₹300</span>
                    </div>
                </div>
                </Fade>
            </div>
    </Col>
</Row>
</Container> */}

<Container className='mt-5 mb-5'>
<Row>
    <Col md={4}>
    <Zoom>
    <h1 className='heading1'>What's In Trend </h1>
    </Zoom>
    </Col>
    <Col><Divider  className='divider mt-4 d-none d-sm-block'  style={{}} /></Col>
    <Col md={3}>
    <Zoom>
      <Button variant="danger" className='c-btn '> <a href='/#/Books' className='link2'>View All</a></Button>{' '}
      </Zoom>
    </Col>
</Row>
</Container>

  {/* what trend section start */}

<Container>
<Row className=' mt-5'>
    <Col lg={6} md={6} sm={12}>
    <div class="card-horizontal mb-2 shadows_view" style={{backgroundColor:'white',borderRadius:'20px'}}>
                    <div class="img-square-wrapper p-3">
                    <Link to="/cg_objective"><img class="img-fluid img-what_trend" src="./images/cgfront.jpg" style={{width:'170px',height:'250px'}} alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-3">
                    <Link to="/cg_objective" className='link'>  <h3 class="card-title mt-3">छत्तीसगढ़ सम्पूर्ण वस्तुनिष्ठ</h3></Link>
                    <Link to="/Authors" className='link'><h6 className='mt-3'><span>राहुल वर्मा, जय कुमार सिंह, सागर नायक </span></h6></Link>
                    <p class="card-text">छत्तीसगढ़ की प्रतियोगी परीक्षाओं के लिए.</p>
                    {/* <span>₹300</span> */}
                    {/* <Button variant="danger" className='c-btn-without-arrow'> <a href='/cg_objective' className='link2'>Read Now</a></Button>{' '} */}
                    <Button variant="danger" className='c-btn-without-arrow mb-3'><a href='/#/cg_objective' className='link2'>Read More</a> </Button>{' '}
                    </div>
                </div>
    </Col>
    <Col lg={6} md={6} sm={12}>
    <div class="card-horizontal mt-2 shadows_view" style={{backgroundColor:'white',borderRadius:'20px'}}>
                    <div class="img-square-wrapper p-3">
                    <Link to="/cgobjective_explain"> <img class="img-fluid img-what_trend" src="./images/cgexplain.jpeg" style={{width:'170px',height:'250px'}} alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-3">
                    <Link to="/cgobjective_explain" className='link'> <h3 class="card-title mt-3">छत्तीसगढ़ सम्पूर्ण वस्तुनिष्ठ</h3></Link>
                    <Link to="/Authors" className='link'> <h6 className='mt-3'><span> डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </span></h6></Link>
                     <p class="card-text">छत्तीसगढ़ की प्रतियोगी परीक्षाओं के लिए <strong>व्याख्यात्मक स्वरूप में</strong>.</p>
                    {/* <span>₹300</span> */}
                    <Button variant="danger" className='c-btn-without-arrow'> <a href='/#/cgobjective_explain' className='link2'>Read Now</a></Button>{' '}
                    </div>
                </div>
    </Col> 


</Row>
</Container>


{/* 
</Container> */}
    </div>
  )
}

export default Dealofweek