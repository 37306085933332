import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
// import { Chip } from '@mui/material';
import '../style.css';
function Hindi() {
  return (
    <div>

<Container>
  <Row className="mt-5 mb-5 ">
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/front.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col sm={12} md={8} className='text-BD'> 
<h2 className=" " style={{textAlign:'left'}}>हिंदी का सम्पूर्ण व्याकरण</h2>
<p> By <span>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</span></p>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1' className='link2'>Purchase Now</a></Button>{' '}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<p style={{textAlign:'justify'}}>  शिक्षा-संबंधी राष्ट्रीय नीति-संकल्प के अनुरूप विश्वविद्यालयों में उच्चतम स्तर के शिक्षा के मानदण्डों के अनुसार छत्तीसगढ में अध्ययनरत विद्यार्थियों के लिए ' <strong>हिन्दी का सम्पूर्ण व्याकरण </strong>( छत्तीसगढ के विशेष संदर्भ में ) ' ग्रंथ ( पृ सं- 438 ) का प्रकाशन छत्तीसगढ शासन के संस्कृति विभाग के सहयोग से किया गया है। ग्रंथ के लेखक सुप्रसिद्ध भाषाविद् <strong>डाॅ. विनय कुमार पाठक</strong>  और <strong>डाॅ. विनोद कुमार वर्मा </strong>  हैं।</p>
  
  <p style={{textAlign:'justify'}}> <strong>लेखकीय अभिमत :- </strong>    लेखक ने अपनी भूमिका में लिखा है- ' हमें विश्वास है कि नौ खण्डों में विभाजित व 49 अध्यायों को  समेटे यह ग्रंथ हिन्दी विषय के स्नातक एवं स्नातकोत्तर कक्षाओं के विद्यार्थियों के लिए बहु उपयोगी और मार्गदर्शक तो सिद्ध होगा ही, व्याकरण के प्रायः सभी पक्षों को एक स्थल पर प्रस्तुत कर यह बहुप्रतीक्षित ग्रंथ समग्रता को समेटने का संयोजन भी सिद्ध होगा। आज आपके समक्ष इस ग्रंथ को राष्ट्रीय क्षितिज पर स्थापित करते हुए हमें गर्व और गौरव की अनुभूति हो रही है। व्याकरण की संपूर्णता को गागर में सागर या बूंद में समुद्र-सद्दृश संधारण करने वाला यह ग्रंथ न केवल साहित्य और भाषा के अध्येताओं, शोधकर्ताओं और हिन्दी के प्रति सजग-सचेष्ट सर्जकों और पाठकों को प्रबुद्ध करेगा, वरन् हिंदी की समझ विकसित करने वालों को भी प्रबुद्ध करेगा। '</p>
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Paperback</h6>
    <h6><strong>pages:</strong> 192</h6>
    <h5 className=" mt-5"><strong>TAGS</strong></h5>
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} /> */}
    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>
</Container>

<Container>
    <Row>
        <Col sm={12} md={12} lg={12}>
            <h4 style={{color:'#F65D4E'}}><strong>संदेश -</strong></h4>
<p style={{textAlign:'justify'}}>     अपने संदेश में <strong>न्यायमूर्ति गौतम चौरड़िया </strong>ने लिखा है- ' यह ग्रंथ अकादमिक दृष्टिकोण से  - महाविद्यालयीन और स्कूली पाठ्यक्रम हेतु उपयोगी तो है ही, साथ ही यह प्रतियोगी परीक्षाओं के लिए भी उतने ही महत्व का है क्योंकि यह पढ़ने में सरल और बोधगम्य है। दोनों ही विद्वान लेखकों को मैं कई वर्षों से जानता हूँ। इनकी अनेक कृतियों को विश्वविद्यालयों के पाठ्यक्रम में शामिल किया गया है व संदर्भ ग्रंथ के रूप में भी पढ़ा जाता है। इन्हें मानक पुस्तकों के लेखन के कारण देश-प्रदेश में जाना जाता है। हिन्दी का सम्पूर्ण व्याकरण भी एक मानक ग्रंथ है। '<br/>
अपने संदेश में <strong> श्री अन्बलगन पी ( आई. ए. एस. )</strong> ने लिखा है- ' ग्रंथ के कुल 09 खंडों में 49 अध्याय द्वारा विश्व की भाषाओं,  राजभाषा, संवैधानिक प्रावधान, शास्त्रीय भाषा एवं भारत की प्राचीन भाषाओं को विस्तारपूर्वक बताया गया है। संप्रति,  हिन्दी के विविध शब्द रूपों को समाहित करते हुए  भाषा की बारीकियों को ध्यान में रखकर इस व्याकरण ग्रंथ को रचा गया है जो हिन्दी ज्ञान कोष के लिए बहुमूल्य मोती साबित होगा। '</p>
       
       <h4 style={{color:'#F65D4E'}}><strong>पाठकीय अभिमत</strong> </h4>
       <p>   इस ग्रंथ के बारे में ढेर सारी पाठकीय प्रतिक्रियायें अमेजान आदि में पढ़ने को मिल रही है जिसमें से केवल एक-दो का उल्लेख संक्षेप में सांकेतिक रूप में दिया जा रहा है-</p>
       <p style={{textAlign:'justify'}}> <strong>अनुपम गुप्ता</strong>  ने 25 फरवरी 2021 को लिखा है- ' यह पुस्तक स्नातक और स्नातकोत्तर छात्रों के लिए अत्यन्त ज्ञानवर्धक और उपयोगी है जो हिन्दी भाषा में विशिष्ट स्तर की विशेषज्ञता हासिल कर रहे हैं। इसके अलावा यह उन छात्रों के लिए काफी उपयोगी है जो प्रतियोगी परीक्षाओं की तैयारी कर रहे हैं। हिन्दी व्याकरण की यह पुस्तक अद्भूत और दिलचस्प है। '</p>
       <p style={{textAlign:'justify'}}> <strong>श्रीजानी डी</strong>  ने 06 मार्च 2022 को लिखा है- ' यह अकादमिक दृष्टिकोण से अत्यन्त महत्वपूर्ण ग्रंथ है।  इसमें भाषा के विकास के साथ व्याकरण संबंधी गूढ़ बातों को तथ्यों के साथ दर्शाया गया है।  मुझे पुरा विश्वास है कि यह ग्रंथ देश-विदेश में रह रहे हर वर्ग के लोगों के लिए बहु उपयोगी एवं मार्गदर्शक साबित होगा एवं अभी तक लिखे गए हिन्दी व्याकरण ग्रंथों में महत्वपूर्ण स्थान हासिल करेगा। '</p>
       
        </Col>
    </Row>
</Container>

    </div>
  )
}

export default Hindi