import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
// import { Chip } from '@mui/material';
import '../style.css';
function machhuaare() {
  return (
    <div>
<Container>
  <Row className="mt-5 mb-5 ">
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/Machhuware.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col sm={12} md={8} className='text-BD mt-5'> 
<h2 className=" " style={{textAlign:'left'}}>मछुआरे की लड़की</h2>
<p> By <span> डॉ. विनोद कुमार वर्मा</span></p>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1' className='link2'>Purchase Now</a></Button>{' '} */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<p style={{textAlign:'justify'}}>  हिन्दी के सुप्रसिद्ध विद्वान, चिंतक,  समीक्षक  डाॅ. सत्येन्द्र शर्मा ने डाॅ. विनोद कुमार वर्मा कृत <strong>' मछुआरे की लड़की'</strong>  को हिन्दी की श्रेष्ठ कहानियों में से एक माना है। प्रकृत्याँचल के बड़े केनवास पर उकेरी गई यह कहानी <strong>' कथा शिल्प' </strong> की दृष्टिकोण से बेजोड़ है।  यह गरीब परिवार के घरौंदे में पली,  विकलाँगता का दंश झेल रही एक नामालूम-सी लड़की की नर्मदा के विकराल बाढ़ से जूझकर शताधिक लोगों को बचाने की, मानवीय संवेदनाओं को झकझोर देने वाली अद्भूत गाथा है। डाॅ विनोद कुमार वर्मा की कलम से निःसृत इस कथा ने 30 अगस्त 1973 को होशंगाबाद में आई प्रलयंकारी बाढ़ से मछुआरन लड़की सरस्वती के जूझने की घटना को जीवांत कर दिया है। इस कथा को छत्तीसगढ के बस्तर विश्वविद्यालय सहित कई प्रदेशों के विश्वविद्यालयीन पाठ्यक्रम में शामिल किया गया है।</p>
  
  {/* <p style={{textAlign:'justify'}}> <strong>लेखकीय अभिमत :- </strong>    लेखक द्वय ने अपनी भूमिका में लिखा है- ' हमें विश्वास है कि नौ खण्डों में विभाजित व उनचास अध्यायों को  समेटे यह ग्रंथ हिन्दी विषय के स्नातक एवं स्नातकोत्तर कक्षाओं के विद्यार्थियों के लिए बहूपयोगी और मार्गदर्शक तो सिद्ध होगा ही, व्याकरण के प्रायः सभी पक्षों को एक स्थल पर प्रस्तुत कर यह बहुप्रतीक्षित ग्रंथ समग्रता को समेटने का संयोजन भी सिद्ध होगा। आज आपके समक्ष इस ग्रंथ को राष्ट्रीय क्षितिज पर स्थापित करते हुए हमें गर्व और गौरव की अनुभूति हो रही है। व्याकरण की संपूर्णता को गागर में सागर या बूंद में समुद्र-सद्दृश संधारण करने वाला यह ग्रंथ न केवल साहित्य और भाषा के अध्येताओं, शोधकर्ताओं और हिन्दी के प्रति सजग-सचेष्ट सर्जकों और पाठकों को प्रबुद्ध करेगा, वरन् हिंदी की समझ विकसित करने वालों को भी उद्बुद्ध करेगा। '</p> */}
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Paperback</h6>
    <h6><strong>pages:</strong> 192</h6>
    <h5 className=" mt-5"><strong>TAGS</strong></h5>
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} /> */}
    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>
</Container>

    </div>
  )
}

export default machhuaare
