import React from 'react'
import AboutUs from '../components/AboutUs';

function Publish() {
  return (
    <div>

<AboutUs/>

    </div>
  )
}

export default Publish