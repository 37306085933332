import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';
// import { Link} from 'react-router-dom';
import '../style.css';
import Image from 'react-bootstrap/Image';
import Zoom from 'react-reveal/Zoom';

function Sagarnayak() {
  return (
    <div>

<Container>

<Row className='mt-5'>
        <Col lg={12} md={12} sm={12}>
            <Zoom>
       <h1 className="one" style={{textAlign:'left',color:'#ff8664'}}><i>Author Overview</i></h1>
       </Zoom>
        </Col>
    </Row>

    <Row className="mb-5" style={{marginTop:'60px'}} >

        <Col sm={12} md={4} lg={4}>
        <Image className="frame img" src="../images/sagarnayak.jpeg" width={'350px'} height={'350px'}/>
            <h3 className="text p-3"  style={{textAlign:'center'}}>    सागर नायक  </h3>
        </Col>

        <Col sm={12} md={8} className="mt-2 mb-4 "> 
        {/* <h2 className="" style={{textAlign:'center',color:'black'}}>Author Overview</h2> */}
        <p className="mt-3" style={{textAlign:'justify'}}> छत्तीसगढ के माटी की सौंधी-सौंधी सुगंध को समेटे,  रायगढ जिले के छोटे-से गाँव कुसमुरा में पले-बढ़े सागर नायक का जन्म 05 अगस्त 1992 को रायगढ़ में हुआ। स्कूली शिक्षा दुर्ग एवं बिलासपुर में पुरा करने के बाद वर्ष 2014 में सी. वी. रामन विश्वविद्यालय से <strong>बैचलर आफ इंजीनियरिंग </strong>की परीक्षा <strong>प्रथम श्रेणी</strong> में उत्तीर्ण की। जहाँ वर्ष <strong>2016</strong>  में छत्तीसगढ राज्य व्यवसायिक मंडल द्वारा आयोजित <strong>पटवारी चयन परीक्षा</strong> में वे चयनित हुए वहीं लोक सेवा आयोग छत्तीसगढ द्वारा आयोजित <strong>राज्य प्रशासनिक  सेवा परीक्षा 2018</strong>  की चयन सूची में गौरवशाली <strong>235 वाँ</strong> स्थान प्राप्त किया। राज्य प्रशासनिक सेवा में उच्च स्थान प्राप्त करने के लिए वे अभी भी अध्ययनरत हैं। संप्रति, वे <strong>' छत्तीसगढ सम्पूर्ण वस्तुनिष्ठ '</strong> के सहलेखक भी हैं जो छत्तीसगढ में चर्चित रही है।
    </p>
         
            </Col> 
    </Row>
</Container>

    </div>
  )
}

export default Sagarnayak