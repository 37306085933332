import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
// import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
// import { FaFacebookSquare } from "react-icons/fa";
 //import { FaLinkedin} from "react-icons/fa";
  // import {  FaTwitterSquare} from "react-icons/fa";
  //  import {  FaInstagram } from "react-icons/fa";
//import NavDropdown from 'react-bootstrap/NavDropdown';
// import Form from 'react-bootstrap/Form';
// import { NavLink } from "react-router-dom";
function NavBar() {
  // const [activeIndex, setActiveIndex] = React.useState(0);
  // const changeStyle = () => {
  //   console.log("you just clicked");
  //   color: #F65D4E;
  //   setStyle("active");
  // };
  // const handleOnClick = index => {
  //   setActiveIndex(index); / remove the curly braces
  // };
  return (
    <Navbar collapseOnSelect expand="lg" style={{backgroundColor:'#F6F0BC '}} variant="dark" >  
    {/* //#abb8c3 */}
      <Container>
        <Navbar.Brand > <img
              alt=""
              src="../images/logovadanya.png"
              width="150"
              height="80"
              className="d-inline-block align-top"
            />{' '}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor:'black'}}/>
        <Navbar.Collapse id="responsive-navbar-nav" style={{height:'70px'}}>
          {/* <Nav className='ml-5 d-none'>
             <Nav.Link href="#features">Home</Nav.Link>
             <Nav.Link href="/Books">Books</Nav.Link>
          </Nav> */}
          <Nav className="me-auto" style={{marginLeft:'8rem'}}>
          {/* <NavLink to="users" className={({ isActive }) => (isActive ? 'bg-green-500 font-bold' : 'bg-red-500 font-thin')}>
           Users
           </NavLink> */}
            <Nav.Link href="/" className='nav_h'>Home</Nav.Link>
            <Nav.Link href="/#/about" className='nav_h'>About Us</Nav.Link>
            <Nav.Link href="/#/Books" className='nav_h isActive'>Books</Nav.Link>
            <Nav.Link href="/#/Authors" className='nav_h'>Authors</Nav.Link>
          
            <Nav.Link href="/#/Contact" className='nav_h'>Contact Us</Nav.Link>
            {/* <NavDropdown title="Dropdown" className='nav_h' id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <Form className="justify-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
           
          </Form> */}
          </Nav>
        
          <Nav className='nav_link nav_link_mobile'>
         
          <Nav.Link href="/"> <img
              alt=""
              src="../images/telephone.png"
              width="32px"
              height="32px"
              className="d-inline-block align-top"
              style={{marginTop:'0px'}}
            /></Nav.Link>
            <h6 style={{marginTop:'21px'}}>Call Us</h6>
            <Nav.Link href="tel: +917771030030" className='nav_num_h'> +91 77710-30030</Nav.Link>
          {/* <Form className="justify-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
           
          </Form> */}
          {/* <ul>
            <li style={{display:'inline',color:'blue',fontSize:'24px'}}  className='socail'><a href=""><FaFacebookSquare className='facebook'/></a> </li>
            <li  className='socail' style={{display:'inline',color:'blue',fontSize:'24px'}}><a href=""><FaLinkedin  className='linkdin'/></a></li>
            <li className='socail' style={{display:'inline'}}> <a href=''><SubscriptionsRoundedIcon fontSize="large"  sx={{ color: "red" }}/></a></li>
            <li  className='socail'  style={{display:'inline',color:'blue',fontSize:'24px'}}><a href="">< FaTwitterSquare  className='linkdin'/></a></li>
            <li  className='socail'  style={{display:'inline',color:'pink',fontSize:'24px'}}><a href="">< FaInstagram  className='insta'/></a></li>
        
          </ul> */}
            {/* <Nav.Link href="#" className='linkdin'>in</Nav.Link>
            <Nav.Link eventKey={2} href="#">
            <FacebookOutlinedIcon fontSize="large"  color="primary"/>
            </Nav.Link>
            <Nav.Link href="#deets">
              <SubscriptionsRoundedIcon fontSize="large"  sx={{ color: "red" }}/>
            </Nav.Link> */}
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>



  );
}

export default NavBar;