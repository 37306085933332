import React from 'react'
import AllAuthors from '../components/AllAuthors';
function Authors() {
  return (
    <div>

<AllAuthors/>

    </div>
  )
}

export default Authors