import React from 'react'

import ContactUs from '../components/ContactUs';
function Contact() {
  return (
    <div>

<ContactUs/>

    </div>
  )
}

export default Contact