import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';
// import {Link} from "react-router-dom";
//import Card from 'react-bootstrap/Card';
// import Form from 'react-bootstrap/Form';
import '../style.css';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Zoom from 'react-reveal/Zoom';
function AllAuthors() {
  return (
    <div>
        {/* <Container>
            <Row>
                <Col lg={3} md={3}></Col>
                <Col>
                <Form className="d-flex mt-5">
            <Form.Control
              type="search"
              placeholder="Search Author's"
              className="me-2 mb-4"
              aria-label="Search"
            />
            <Button className='btn_search' style={{backgroundColor: '#0E204D'}}>Search</Button>
          </Form>
                </Col>
                <Col lg={3} md={3}></Col>
            </Row>
        </Container> */}

{/* <Container>
<Row className='mt-5'>
        <Col lg={12} md={12} sm={12}>
            <Zoom>
       <h1 className="one" style={{textAlign:'left',color:'#ff8664'}}><i>All Authors</i></h1>
       </Zoom>
        </Col>
    </Row>

<Row className=' mt-5'>
    <Col lg={4} md={4} sm={12}>
    <div class=" mb-2">
                    <div class="img-square-wrapper p-3">
                <a href='/#/vinod_pathak_ji' className='link'><img class="img-fluid " src="./images/pathakji_bgr.png" alt="Card image cap" style={{height:'240px',width:'350px',borderRadius:'25px'}} /></a>     
                    </div>
                    <div class="card-body p-3">
                    <a href='/#/vinod_pathak_ji' className='link'> <h3 class="card-title">डॉ. विनय कुमार पाठक</h3> </a> 

                        <p class="card-text cardtext mt-2">
पूर्व अध्यक्ष छत्तीसगढ़ राज्य भाषा आयोग
( राज्य मंत्री दर्जा ) छत्तीसगढ़ शासन
                        </p>
                        <a href='/#/vinod_pathak_ji' className='link'><strong>Read More</strong></a>
                    </div>
                </div>
    </Col>
    <Col lg={4} md={4} sm={12}>
    <div class=" mb-2">
                    <div class="img-square-wrapper p-3">
                    <a href='//3tinfosystems.com' className='link'>        <img class="img-fluid " src="./images/vinodji_fresh.jpeg" alt="Card image cap" style={{height:'240px',width:'350px',borderRadius:'25px'}} /></a>
                    </div>
                    <div class="card-body p-3">
                    <a href='//3tinfosystems.com' className='link'>    <h3 class="card-title">डॉ. विनोद कुमार वर्मा</h3> </a>

                        <p class="card-text cardtext mt-2">हिंदी व्याकरण की मानक मार्गदर्शिका.
                        </p>
                        <a href='//3tinfosystems.com' className='link'>  <strong>Read More</strong></a>
                    </div>
                </div>
    </Col> 
    
    <Col lg={4} md={4} sm={12}>
    <div class=" mb-2">
                    <div class="img-square-wrapper p-3">
                    <Link to="/AuthorDetails" className='link'>        <img class="img-fluid " src="./images/jai.jpeg" alt="Card image cap" style={{height:'240px',width:'350px',borderRadius:'25px'}} /></Link>
                    </div>
                    <div class="card-body p-3">
                    <Link to="/AuthorDetails" className='link'>    <h3 class="card-title">जय कुमार सिंह </h3> </Link>

                        <p class="card-text cardtext mt-2">22 मई 1984 को उर्जाधानी कोरबा में जन्में,  छत्तीसगढ की माटी में पले-बढ़े जय कुमार सिंह बचपन से ही अत्यन्त प्रतिभाशाली और मेहनती रहें हैं। उन्होंने स्कूली शिक्षा कोरबा से प्राप्त करने के बाद शासकीय इंजीनियरिंग कालेज बिलासपुर से वर्ष 2008 में बी.ई. (इलेक्ट्रिकल इंजीनियरिंग) की डिग्री प्रथम श्रेणी में उत्तीर्ण की। 
                        </p>
                        <Link to="/AuthorDetails" className='link'>  <strong>Read More</strong></Link>
                    </div>
                </div>
    </Col>

    <Col lg={4} md={4} sm={12}>
    <div class=" mb-2">
                    <div class="img-square-wrapper p-3">
                    <Link to="/rahulverma" className='link'>        <img class="img-fluid " src="./images/rahulverma.jpeg" alt="Card image cap" style={{height:'240px',width:'350px',borderRadius:'45px'}} /></Link>
                    </div>
                    <div class="card-body p-3">
                    <Link to="/rahulverma" className='link'>    <h3 class="card-title">राहुल वर्मा</h3> </Link>

                        <p class="card-text cardtext mt-2" > छत्तीसगढ की माटी में पले-बढ़े राहुल वर्मा का जन्म 07 मार्च 1988 को संस्कारधानी बिलासपुर में हुआ। वे बचपन से ही प्रतिभाशाली थे। उन्होंने प्राथमिक, माध्यमिक एवं उच्चतर माध्यमिक शिक्षा आनर्स के साथ प्रथम श्रेणी में उत्तीर्ण की।
                        </p>
                        <Link to="/rahulverma" className='link'>  <strong>Read More</strong></Link>
                    </div>
                </div>
    </Col>

    <Col lg={4} md={4} sm={12}>
    <div class=" mb-2">
                    <div class="img-square-wrapper p-3">
                    <Link to="/sagarnayak" className='link'>        <img class="img-fluid " src="./images/sagarnayak.jpeg" alt="Card image cap" style={{height:'240px',width:'350px',borderRadius:'25px'}} /></Link>
                    </div>
                    <div class="card-body p-3">
                    <Link to="/sagarnayak" className='link'>    <h3 class="card-title">सागर नायक</h3> </Link>

                        <p class="card-text cardtext mt-2">छत्तीसगढ के माटी की सौंधी-सौंधी सुगंध को समेटे,  रायगढ जिले के छोटे-से गाँव कुसमुरा में पले-बढ़े सागर नायक का जन्म 05 अगस्त 1992 को रायगढ़ में हुआ।
                        </p>
                        <Link to="/sagarnayak" className='link'>  <strong>Read More</strong></Link>
                    </div>
                </div>
    </Col>
 

</Row>

</Container> */}


<Container>
    <Row className='mt-5'>
        <Col lg={12} md={12} sm={12}>
            <Zoom>
       <h1 className="one" style={{textAlign:'left',color:'#ff8664'}}><i>All Authors</i></h1>
       </Zoom>
        </Col>
    </Row>

    <Row className='mt-5'>
    
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="./images/pathakji_bgr.png" width={'100%'} height={'300px'} style={{borderRadius:'20px'}}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}> डॉ. विनय कुमार पाठक</h2><br/>
</Zoom>
{/* <p> By <span>डॉ. विनोद कुमार वर्मा</span></p> */}
{/* <p> ₹ 999.00 inclusive of all taxes</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h4>About Author </h4>
<p className='' style={{textAlign:'justify'}}> " 11 जून 1946 को बिलासपुर में जन्मे डॉ. पाठक ने हिंदी और भाषा विज्ञान में पी.एच.डी और डि.लीट. की दोहरी उपाधि प्राप्त कर अकादमिक दृष्टि से अखिल भारतीय स्तर पर जहां कीर्तिमान स्थापित किया है, वहीं 63 शोधार्थियो ने आप के निर्देशन में पीएचडी की उपाधि अर्जित की है! छत्तीसगढ़ी भाषा साहित्य एवं संस्कृति में सर्वाधिक कार्य करके एवं करा कर आप छत्तीसगढ़ के जीवंत विश्वकोश के रूप में विश्व विख्यात है! </p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/vinod_pathak_ji' className='link2'>Read More</a></Button>{' '}
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Market</h6> */}
    {/* <h6><strong>pages:</strong> 192</h6> */}
    {/* <h5 className=" mt-5"><strong>TAGS</strong></h5>
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} /> */}
    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="./images/vinodji_fresh.jpeg" width={'100%'} height={'350px'} style={{borderRadius:'20px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>डॉ. विनोद कुमार वर्मा</h2><br/>
</Zoom>
{/* <p> By <span>राहुल वर्मा, जय कुमार सिंह, सागर नायक</span></p> */}
<h4>About Author</h4>
<p className='' style={{textAlign:'justify'}}> " 22 फरवरी 1954 को नवगठित सक्ती जिले के ग्राम फगुरम में जन्में छत्तीसगढ की माटी में पले-बढ़े डाॅ. विनोद कुमार वर्मा छत्तीसगढ के एक लोकप्रिय कृषि वैज्ञानिक तो हैं ही, इसके साथ ही हिन्दी और छत्तीसगढी साहित्य में उनका महती योगदान है। वे गहन संवेदना के कथाकार हैं। उनकी राष्ट्रीय स्तर पर चर्चित कहानी ' मछुआरे की लड़की ' को बस्तर विश्वविद्यालय सहित अनेक प्रदेशों के विश्वविद्यालयों के पाठ्यक्रम में शामिल किया गया है। प्रकृत्याँचल के बड़े केनवास पर उकेरी गई यह कहानी ' कथा शिल्प ' की दृष्टिकोण से बेजोड़ है।.</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'><a href='https://vinodkumarverma.in/'   target="_blank"  rel="noreferrer" className='link2'>Read More</a> </Button>{' '}
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Market</h6> */}
    {/* <h6><strong>pages:</strong> 192</h6> */}
    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="./images/jai.jpeg" width={'100%'} height={'300px'} style={{borderRadius:'20px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>जय कुमार सिंह</h2><br/>
</Zoom>
{/* <p> By <span>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </span></p> */}
<h4>About Author </h4>
<p className='' style={{textAlign:'justify'}}>22 मई 1984 को कोरबा में जन्में, छत्तीसगढ की माटी में पले-बढ़े जय कुमार सिंह बचपन से ही अत्यन्त प्रतिभाशाली और मेहनती रहें हैं। उन्होंने स्कूली शिक्षा कोरबा से प्राप्त करने के बाद शासकीय इंजीनियरिंग कालेज बिलासपुर से वर्ष 2008 में बी.ई. (इलेक्ट्रिकल इंजीनियरिंग) की डिग्री प्रथम श्रेणी में उत्तीर्ण की। उच्चाकाँक्षाओं से पूरित-प्रेरित उनकी जीवनशैली सतत् संघर्षो की कहानी है।</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/AuthorDetails' className='link2'>Read More</a></Button>{' '}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='https://www.amazon.in/CHHATTISGARH-SAMPURNA-VYAKARAN-Vinod-Kumar/dp/8193253418/ref=pd_bxgy_img_sccl_1/260-7835383-6341028?pd_rd_w=wodsk&content-id=amzn1.sym.d68c4347-8b80-4998-9474-4671a1e32e96&pf_rd_p=d68c4347-8b80-4998-9474-4671a1e32e96&pf_rd_r=0EM0G56CXNF0P8KF9M92&pd_rd_wg=A2TIR&pd_rd_r=fa0ae163-5af1-4235-beab-7c384132da8a&pd_rd_i=8193253418&psc=1' className='link2'>Purchase Now</a></Button>{' '} */}
    {/* <h6><strong>pages:</strong> 384</h6>
    <h6><strong>Available in: </strong> Market / Online</h6> */}
    {/* <h6><strong>pages:</strong> 192</h6> */}
    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="./images/rahulverma.jpeg" width={'100%'} height={'300px'} style={{borderRadius:'20px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>राहुल वर्मा</h2><br/>
</Zoom>
{/* <p> By <span>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </span></p> */}
<h4>About Author </h4>
<p className=' ' style={{textAlign:'justify'}}>छत्तीसगढ की माटी में पले-बढ़े राहुल वर्मा का जन्म 07 मार्च 1988 को संस्कारधानी बिलासपुर में हुआ। वे बचपन से ही प्रतिभाशाली थे। उन्होंने प्राथमिक, माध्यमिक एवं उच्चतर माध्यमिक शिक्षा आनर्स के साथ प्रथम श्रेणी में उत्तीर्ण की। स्नातक परीक्षा में उन्होंने विश्वविद्यालय की प्रावीण्य सूची में स्थान प्राप्त किया। </p>

<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/rahulverma' className='link2'>Read More</a></Button>{' '}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1' className='link2'>Purchase Now</a></Button>{' '} */}
   

    {/* <h6><strong>pages:</strong> 438</h6>
    <h6><strong>Available in: </strong> Market / Online</h6> */}
    {/* <h6><strong>pages:</strong> 192</h6> */}
    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="./images/sagarnayak.jpeg" width={'100%'} height={'300px'} style={{borderRadius:'20px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>सागर नायक</h2><br/>
</Zoom>
{/* <p> By <span>डॉ. विनोद कुमार वर्मा</span></p> */}
<h6>About Author </h6>
<p className=' ' style={{textAlign:'justify'}}> छत्तीसगढ के माटी की सौंधी-सौंधी सुगंध को समेटे, रायगढ जिले के छोटे-से गाँव कुसमुरा में पले-बढ़े सागर नायक का जन्म 05 अगस्त 1992 को रायगढ़ में हुआ। स्कूली शिक्षा दुर्ग एवं बिलासपुर में पुरा करने के बाद वर्ष 2014 में सी. वी. रामन विश्वविद्यालय से बैचलर आफ इंजीनियरिंग की परीक्षा प्रथम श्रेणी में उत्तीर्ण की।</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/sagarnayak' className='link2'>Read More</a></Button>{' '}
    {/* <h6><strong>pages:</strong> 199</h6>
    <h6><strong>Available in: </strong> Market</h6> */}
    {/* <h6><strong>pages:</strong> 199</h6> */}
    </Col>
    </Row>

</Container>

    </div>
  )
}

export default AllAuthors