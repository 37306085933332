import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
//import { Chip } from '@mui/material';
import '../style.css';
function Vimarsh() {
  return (
    <div>

<Container>
  <Row className="mt-5 mb-5 ">
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/vimarsh.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col sm={12} md={8} className='text-BD mt-5'> 
<h2 className=" " style={{textAlign:'left'}}> विमर्श के निष्कर्ष पर छत्तीसगढ़ी</h2>
<p> By <span> डॉ. विनोद कुमार वर्मा</span></p>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1' className='link2'>Purchase Now</a></Button>{' '} */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<p style={{textAlign:'justify'}}>   ' विमर्श के निष्कर्ष पर छत्तीसगढी ' का अर्थ है- <strong>' समीक्षा की कसौटी पर छत्तीसगढी भाषा '</strong> ( विमर्श = समीक्षा,  निष्कर्ष = कसौटी )। जैसे ' सोना ' की कसौटी करने के बाद ही सोनार यह बताता है कि वह कितने कैरेट का है या कितना शुद्ध है। इस ग्रंथ में डाॅ. विनोद कुमार वर्मा ने <strong>' छत्तीसगढी भाषा'</strong> की ठीक वैसे ही कसौटी करते हुए छत्तीसगढी गद्य लेखन में फैली अराजकता की तथ्यपरक विवेचना कर उसके कारणों और विसंगतियों की ओर विद्वानों का ध्यान आकर्षित किया है। 
       इस ग्रंथ के बारे में सुप्रसिद्ध भाषाविद् डाॅ. विनय कुमार पाठक ने लिखा है- ' यह ग्रंथ न केवल शोध अध्येताओं के लिए उपयोगी है बल्कि छत्तीसगढी भाषा को एक दिशा दिखाकर डाॅ. विनोद कुमार वर्मा लेखक के रूप में छत्तीसगढी भाषा के श्रेष्ठ विमर्शक सिद्ध हुए हैं। यह ग्रंथ छत्तीसगढी भाषा-विमर्श को एक नया आयाम देगा,  इसमें दो मत नहीं। '</p>
  
  {/* <p style={{textAlign:'justify'}}> <strong>लेखकीय अभिमत :- </strong>    लेखक द्वय ने अपनी भूमिका में लिखा है- ' हमें विश्वास है कि नौ खण्डों में विभाजित व उनचास अध्यायों को  समेटे यह ग्रंथ हिन्दी विषय के स्नातक एवं स्नातकोत्तर कक्षाओं के विद्यार्थियों के लिए बहूपयोगी और मार्गदर्शक तो सिद्ध होगा ही, व्याकरण के प्रायः सभी पक्षों को एक स्थल पर प्रस्तुत कर यह बहुप्रतीक्षित ग्रंथ समग्रता को समेटने का संयोजन भी सिद्ध होगा। आज आपके समक्ष इस ग्रंथ को राष्ट्रीय क्षितिज पर स्थापित करते हुए हमें गर्व और गौरव की अनुभूति हो रही है। व्याकरण की संपूर्णता को गागर में सागर या बूंद में समुद्र-सद्दृश संधारण करने वाला यह ग्रंथ न केवल साहित्य और भाषा के अध्येताओं, शोधकर्ताओं और हिन्दी के प्रति सजग-सचेष्ट सर्जकों और पाठकों को प्रबुद्ध करेगा, वरन् हिंदी की समझ विकसित करने वालों को भी उद्बुद्ध करेगा। '</p> */}
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Paperback</h6>
    <h6><strong>pages:</strong> 192</h6>
    <h5 className=" mt-5"><strong>TAGS</strong></h5>
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} /> */}
    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>
</Container>


    </div>
  )
}

export default Vimarsh