import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {BrowserRouter,Routes,Route} from 'react-router-dom';
// import Books from './pages/Books';
// import Contact from './pages/Contact';
// import Publish from './pages/Publish';
// import Authors from './pages/Authors';
// import AuthorDetails from './pages/AuthorDetails';
// import BookDetailpage from './pages/BookDetailpage';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <BrowserRouter>
  // <Routes>
  //   <Route path="/" element={<App/>}/>
  //   <Route path="/Books" element={<Books/>}/>
  //   <Route path="/contact" element={<Contact/>}/>
  //   <Route path="/publish" element={<Publish/>}/>
  //   <Route path="/Authors" element={<Authors/>}/>
  //   <Route path="/AuthorDetails" element={<AuthorDetails/>}/>
  //   <Route path="/BookDetails" element={<BookDetailpage/>}/>
  // </Routes>
  // </BrowserRouter>
  // <React.StrictMode>
     <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
