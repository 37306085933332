import React, {useState, useEffect }  from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Zoom from 'react-reveal/Zoom';
import BusinessIcon from '@mui/icons-material/Business';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import LoadingOverlay from 'react-loading-overlay';
//import axios from 'axios';
//import $ from "jquery";
//import Alert from 'react-bootstrap/Alert';
// import '../Style.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function ContactUs() {
  // const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
   const [phone, setPhone] = useState("");
   const [msg, setMsg] = useState("");
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = React.useState(false);

  const sendEmail = async (e) => {

    e.preventDefault();
  const formData = new FormData();
  formData.append("name", name);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("msg", msg);
  console.log([...formData]);
  setLoading(true);
  setOpen(true);
   const res = await fetch('https://vadanyapublication.com/phpmailer.php',{
    // http://localhost:8004/register
        method: "POST",
        // headers: {
        //     "Content-Type": "application/json"
        // },
        body: formData,
    })  .then( res => {
      document.getElementById("form").reset();
      setEmail("")
      setName("")
      setPhone("")
      setMsg("")
      // setShow(true)
      setLoading(false);
      setOpen(false);
      console.log(res);
      
    }
    );

    const data = await res.json();
    console.log(data);

    // if (data.status === 401 || !data) {
    //     console.log("error")
    // } else {
    //     setShow(true);
    //     setEmail("")
    //     console.log("Email sent")
    // }
}

  useEffect(()=>{
    // async function getdata(){
    //     const res= await 
    //     axios.post(`http://localhost/publication/src/mail`)
    //      .then((res)=>{
    //  console.log(res);});
    }, []);
// const getCustomersData = () => {
//   axios
//   .get("http://localhost:3000/mail.php")
//   .then(data => console.log(data.data))
//   .catch(error => console.log(error));
//   };
//  getCustomersData();


  return (
    <div>
     {
                // show ? <Alert variant="primary" onClose={() => setShow(false)} dismissible>
                //     Your Email Succesfully Send
                // </Alert> : ""


            }
<Container>
  
<Row>
    <Col sm={12} md={12}>
    <Zoom>
    <h3 className=" mt-3 text-center"><strong>Get in touch</strong></h3>
    </Zoom>
    <h6 className=" mt-3 text-center">Have a specific team you want to reach? Write to them on the relevant email address or use the form below.
<br/>Old-fashioned phone calls work too: <strong>+91 98266-77946 / 77710-30030</strong></h6>
    
    </Col>
</Row>

<Row className="mt-5 mb-5">
  <Col sm={12} md={6} className='mt-5'> 

  <Col sm={12} md={12}> 
  <div className='addressbox mt-3 mb-3'>
    < BusinessIcon fontSize="large"  sx={{ color: "#F65D4E" }}/>
  <h3 className=" mt-3 text-left"><strong>Address</strong></h3>
   <p ><strong>वदान्या प्रकाशन :</strong><br/> एम. आई. जी. - 59 , नेहरू नगर ,<br/> बिलासपुर , छत्तीसगढ़</p>
  </div>
  </Col>

 <Row>
  <Col sm={12} md={6}> 
  <div className='phonebox'>
    < PhoneIcon fontSize="large"  sx={{ color: "#F65D4E" }}/>
 <h3 className=" mt-3 text-left"><strong>Phone</strong></h3>
 <p ><strong>Mo. :-</strong> 98266-77946 </p>
 </div>
  </Col>
 
<Col sm={12} md={6}>
<div class="col" className='emailbox'>
< AttachEmailIcon  fontSize="large"  sx={{ color: "#F65D4E" }} />
 <h3 className=" mt-3 text-left"><strong>Email</strong></h3>
  <p>vadanyapublication@gmail.com</p>
 </div>
    </Col>
    </Row>
 {/* <div class="col-md-12"> */}

 


 {/* </div> */}
 </Col>

  <Col sm={12} md={6} className='formbox mt-5'>
  <Form action="" method="post" id="form">
  <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" name="name" onChange={(e) => setName(e.target.value)}  placeholder="Enter your Name" />
        {/* //onChange={(e) => setName(e.target.value)} */}
      </Form.Group> 
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Phone</Form.Label>
        <Form.Control type="phone" name="phone" pattern="^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$" onChange={(e) => setPhone(e.target.value)}  placeholder="Enter Your Number" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Message</Form.Label>
        {/* <Form.Control type="text-area" rows="3" placeholder="Enter Your Message" /> */}
        <textarea class="form-control" name="msg" id="exampleFormControlTextarea1" rows="3"  onChange={(e) => setMsg(e.target.value)} placeholder="Enter Your Message"></textarea>
      </Form.Group>
      <Button variant="danger" name="submit" type="submit"   disabled={loading} onClick={(e)=>{sendEmail(e); setLoading(!loading)}}  className='c-btn'>
          <LoadingOverlay active={loading} spinner text="Submit...">
      Submit
        </LoadingOverlay>
        
        </Button>{' '}
      {/* onClick={sendEmail} */}
      {/* <Button style={{ backgroundColor : '#0E204D'}}>Submit</Button>{' '} */}
    </Form>

    <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Your Email Succesfully Send"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Thank You for contact us our team will be response you as soon as possible..
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="danger" className='c-btn-without-arrow'><a href="https://vadanyapublication.com/" className='link2'>Close</a></Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>

  </Col>
 

</Row>

<Row>
  <Col>
  
  </Col>
</Row>
</Container>
    </div>
  )
}

export default ContactUs