import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
//import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link} from 'react-router-dom';
import '../style.css';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
// import Avatar from '@mui/material/Avatar';
// import Typography from '@mui/material/Typography';
import Zoom from 'react-reveal/Zoom';
// import ImageIcon from '@mui/icons-material/Image';
// import WorkIcon from '@mui/icons-material/Work';
// import BeachAccessIcon from '@mui/icons-material/BeachAccess';

function PopularAuthors() {
  return (
    <div>

<Container>
<Row className='mt-5'>
    <Col md={5}>
      <Zoom>
    <h1 className='heading1'>Popular Authors </h1>
    </Zoom>
    </Col>
    <Col><Divider   className='divider mt-4 d-none d-sm-block'  style={{}} /></Col>
    <Col md={3}>
    <Zoom>
      <Button variant="danger" className='c-btn '> <a href='/#/Authors' className='link2'>View All</a></Button>{' '}
      </Zoom>
    </Col>
</Row>

<Row className='mt-5'>
<Col lg={6} md={6} sm={12}>
    <div class="card-horizontal mb-2">
                    <div class="img-square-wrapper p-3">
                   <a href='/#/vinod_pathak_ji'><img class="img-fluid img-what_trend" src="./images/pathakji_bgr.png" alt="Card image cap"  /></a>
                    </div>
                    <div class="card-body p-4">
                     <a href='/#/vinod_pathak_ji' className='link'> <h3 class="card-title mt-3">डॉ. विनय कुमार पाठक</h3></a>
                    {/* <Link to="/AuthorkDetails" className='link'><h6 className='mt-3'><span> डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </span></h6></Link> */}
                    <p class="card-text mt-4"><b>पूर्व अध्यक्ष छत्तीसगढ़ राज्य भाषा आयोग <br/>( राज्य मंत्री दर्जा ) छत्तीसगढ़ शासन.</b></p>
                    {/* <span>₹300</span> */}
                    </div>
                </div>
    </Col>
    <Col lg={6} md={6} sm={12}>
    <div class="card-horizontal mt-2">
                    <div class="img-square-wrapper p-3">
                    <a href='https://vinodkumarverma.in/' target="_blank"  rel="noreferrer"><img class="img-fluid img-what_trend" src="./images/vinodji_fresh.jpeg" alt="Card image cap"  /></a>
                    {/* <Link to="/"> <img class="img-fluid img-what_trend" src="./images/vinodji_fresh.jpeg" alt="Card image cap"  /></Link> */}
                    </div>
                    <div class="card-body p-3">
                    <a href='https://vinodkumarverma.in/' target="_blank"  rel="noreferrer" className='link'> <h3 class="card-title mt-3">डॉ. विनोद कुमार वर्मा</h3></a>
                    {/* <Link to="/" className='link'> <h3 class="card-title mt-3">डॉ. विनोद कुमार वर्मा</h3></Link> */}
                    {/* <Link to="/Authors" className='link'> <h6 className='mt-3'><span> डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </span></h6></Link> */}
                     <p class="card-text mt-4"><b>हिंदी व्याकरण की मानक मार्गदर्शिका.</b></p>
                    {/* <span>₹300</span> */}
                    </div>
                </div>
    </Col> 
</Row>
</Container>


<Container  className='mt-5'>
    <Row>
        {/* <Col lg={4} md={5} sm={12} >
            <div className='mx-3 p-3' style={{backgroundColor:'#F65D4E',borderRadius:'25px'}}>
    <h2 style={{color:'white',textAlign:'center'}}>Popular Authors</h2>
   <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper',borderRadius:'15px' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 56, height: 56 }}/>
        </ListItemAvatar>
        <Link to="/AuthorDetails" className='link'>  <ListItemText sx={{marginLeft:'15px'}}
       
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline',fontSize:'22px' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
             डॉ. विनय पाठक

              </Typography>
      
            </React.Fragment>
          }
        /></Link>
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 56, height: 56 }}/>
        </ListItemAvatar>
        <Link to="/AuthorDetails" className='link'>   <ListItemText sx={{marginLeft:'15px'}}
      
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline',fontSize:'22px' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
            डॉ. विनोद कुमार वर्मा
              </Typography>
        
            </React.Fragment>
          }
        /></Link>
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 56, height: 56 }}/>
        </ListItemAvatar>
        <Link to="/AuthorDetails" className='link'>     <ListItemText sx={{marginLeft:'15px'}}
          
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline',fontSize:'22px' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
           राहुल वर्मा 
              </Typography>
        
            </React.Fragment>
          }
        /></Link>
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 56, height: 56 }}/>
        </ListItemAvatar>
        <Link to="/AuthorDetails" className='link'> <ListItemText sx={{marginLeft:'15px'}}
      
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline',fontSize:'22px' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              जय कुमार सिंह
              </Typography>
      
            </React.Fragment>
          }
        /></Link>
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start"  >
        <ListItemAvatar>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 56, height: 56 }}/>
        </ListItemAvatar>
        <Link to="/AuthorDetails" className='link'>    <ListItemText sx={{marginLeft:'15px'}}
  
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline',fontSize:'22px' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              सागर नायक
              </Typography>
   
            </React.Fragment>
          }
        /></Link>
      </ListItem>
     
    
    </List>
</div>
        </Col> */}

        <Col lg={12} md={12} sm={12}>
        <Row>
    <Col md={5}>
      <Zoom>
    <h1 className='heading1'>Keep Reading </h1>
    </Zoom>
    </Col>
    <Col><Divider   className='divider mt-4 d-none d-sm-block'  style={{}} /></Col>
    <Col md={3}>
    <Zoom>
      <Button variant="danger" className='c-btn '> <a href='/#/Books' className='link2'>View All</a></Button>{' '}
      </Zoom>
    </Col>
</Row>

<Row>
    {/* <Col>
   
    <div class="card-horizontal mt-4 sidecard">
    <Zoom>
                    <div class="img-square-wrapper p-1">
                    <Link to="/hindi_wayakran">   <img class="img-fluid img-week_deal" src="./images/front.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-4">
                    <Link to="/hindi_wayakran" className='link'><h4 class="card-title">हिंदी का सम्पूर्ण व्याकरण</h4></Link>
                    <Link to="/Authors" className='link'><p class="card-text">डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</p></Link>
                    <span>₹300</span>
                    </div>
                    </Zoom>
                </div>
               
    </Col>
    <Divider className='divider mt-4 d-none d-sm-block'  style={{}} /> */}
<Row>
    <Col md={6}>
  
    <div class="card-horizontal mt-4 sidecard">
    <Zoom>
    {/* <div class="img-square-wrapper p-1">
                    <Link to="/hindi_wayakran">   <img class="img-fluid img-week_deal" src="./images/front.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-4">
                    <Link to="/hindi_wayakran" className='link'><h4 class="card-title">हिंदी का सम्पूर्ण व्याकरण</h4></Link>
                    <Link to="/Authors" className='link'><p class="card-text">डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</p></Link>
                 
                    </div> */}

                    </Zoom>
                </div>

                <div class="card-horizontal mt-4 sidecard">
                   
                   <div class="img-square-wrapper p-1">
                       <Link to="/hindi_wayakran">   <img class="img-fluid img-week_deal" src="./images/front.jpg" alt="Card image cap"  /></Link>
                       </div>
                       <div class="card-body p-4 ">
                       <Link to="/hindi_wayakran" className='link'><h4 class="card-title">हिंदी का सम्पूर्ण व्याकरण</h4></Link>
                       <Link to="/Authors" className='link'>  <p class="card-text">डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</p></Link>
                       {/* <span>₹300</span> */}
                       </div>
                   </div>

                <Divider className='divider mt-4 d-none d-sm-block'  style={{}} />
                <div class="card-horizontal mt-4 sidecard">
                   
                <div class="img-square-wrapper p-1">
                    <Link to="/cg_ka_vyakaran">   <img class="img-fluid img-week_deal" src="./images/front 1.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-4 ">
                    <Link to="/cg_ka_vyakaran" className='link'><h4 class="card-title">छत्तीसगढ़ी का सम्पूर्ण व्याकरण</h4></Link>
                    <Link to="/Authors" className='link'>  <p class="card-text">डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</p></Link>
                    {/* <span>₹300</span> */}
                    </div>
                </div>
   
    </Col>
    <Col md={6}>
    
    {/* <div class="card-horizontal mt-4 sidecard">
    <Zoom>
    <div class="img-square-wrapper">
                    <Link to="/vimarsh_ke_nishkarsh"> <img class="img-fluid img-week_deal" src="./images/vimarsh.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-4">
                    <Link to="/vimarsh_ke_nishkarsh" className='link'> <h4 class="card-title">विमर्श के निष्कर्ष पर छत्तीसगढ़ी</h4></Link>
                    <Link to="/Authors" className='link'>  <p class="card-text">डॉ. विनोद कुमार वर्मा</p></Link>
                
                    </div>
                    </Zoom>
                </div> */}

                <div class="card-horizontal mt-4 sidecard">
                   
                   <div class="img-square-wrapper p-1">
                   <Link to="/vimarsh_ke_nishkarsh">  <img class="img-fluid img-week_deal" src="./images/vimarsh.jpg" alt="Card image cap"  /></Link>
                   </div>
                   <div class="card-body p-4">
                   <Link to="/vimarsh_ke_nishkarsh" className='link'>  <h4 class="card-title">विमर्श के निष्कर्ष पर छत्तीसगढ़ी</h4></Link>
                   <Link to="/Authors" className='link'> <p class="card-text">डॉ. विनोद कुमार वर्मा</p></Link>
                   {/* <span>₹300</span> */}
                   </div>

               </div>
                
                <Divider className='divider mt-5 d-none d-sm-block'  style={{}} />
                <div class="card-horizontal mt-4 sidecard">
                   
                    <div class="img-square-wrapper p-1">
                    <Link to="/machhuare_ki_ladki">  <img class="img-fluid img-week_deal" src="./images/Machhuware.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-4">
                    <Link to="/machhuare_ki_ladki" className='link'>  <h4 class="card-title">मछुआरे की लड़की</h4></Link>
                    <Link to="/Authors" className='link'> <p class="card-text">डॉ. विनोद कुमार वर्मा</p></Link>
                    {/* <span>₹300</span> */}
                    </div>

                </div>
  
          

    </Col>
   
</Row>

</Row>



        </Col>
    </Row>
</Container>
    </div>
  )
}

export default PopularAuthors