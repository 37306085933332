import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
// import { Chip } from '@mui/material';
import '../style.css';

function Cg() {
  return (
    <div>
<Container>
  <Row className="mt-5 mb-5 ">
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/cgfront.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col sm={12} md={8} className='text-BD'> 
<h2 className=" " style={{textAlign:'left'}}>छत्तीसगढ सम्पूर्ण वस्तुनिष्ठ </h2>
<p> By <span>राहुल वर्मा, जय कुमार सिंह, सागर नायक</span></p>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1' className='link2'>Purchase Now</a></Button>{' '} */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<p style={{textAlign:'justify'}}>  सात खण्डों में 43 अध्यायों को समेटे ' छत्तीसगढ सम्पूर्ण वस्तुनिष्ठ ' का <strong>फैक्ट्स रिविज़न सीरीज़ ( Facts Revision Series )</strong> मुख्य रूप से <storng>प्रारंभिक परीक्षा</storng> को लक्ष्य कर लिखा गया एक उपयोगी और महत्वपूर्ण पुस्तक है जिसमें अंतिम उपलब्ध नवीनतम आंकड़ों को तथ्यात्मक जानकारी सहित  406 पृष्ठों में समाहित करते हुए, <strong>वस्तुनिष्ठ प्रश्नों</strong>  के साथ वर्ष 2020 में <strong>वदान्या प्रकाशन</strong> द्वारा प्रकाशित किया गया। परीक्षोपयोगी इस पुस्तक के लेखक <strong>राहुल वर्मा, जय कुमार सिंह व सागर नायक</strong> हैं। इस पुस्तक की काफी चर्चा भी रही क्योंकि इसमें दृष्टव्य नवीनतम जानकारियों का  महत्व परीक्षा की दृष्टिकोण से बहुत ज्यादा था और इसलिए अभ्यर्थियों ने इसे हाथोंहाथ लिया।</p>
  <h4 style={{color:'#F65D4E'}}><strong>लेखकीय अभिमत -</strong> </h4>
  <p style={{textAlign:'justify'}}> हमने अक्सर देखा है और समझा भी है कि हमारे पास ज्ञान का संचित भंडार तो बहुत है - भरा हुआ है, हमने पढ़ भी लिया है परन्तु परीक्षा हाल से बाहर निकलने के बाद न जाने क्यों हमें ऐसा लगता है कि कुछ प्रतिशत अंक बढ़ सकते थे- अगर हम जानते-बूझते, छोटी-मोटी गलतियाँ न करते। ' कट आफ ' से बाहर हो जाने पर हम दुखी भी होते हैं- खासकर यदि 3 - 4 प्रतिशत तक का ही अंतर हो तो वह स्थित सचमुच हमारे लिए कष्टपूर्ण हो जाती है। <strong>' छत्तीसगढ वस्तुनिष्ठ '</strong>  का <strong>' फैक्ट्स रिविज़न सीरीज़ '</strong> हमें उस स्थित से उबारने में मदद कर सकता है जो छोटी-मोटी गलतियों या भ्रम के कारण अक्सर होता है। हमें विश्वास है कि परीक्षा हाल में जाने से कुछ घण्टों पूर्व आप जिस पुस्तक के पन्नों को अलट-पलट कर विषय-वस्तु तक जल्दी से जल्दी पहुँचना चाहते हैं या किसी भ्रम को दूर करना चाहते हैं- तो वह पुस्तक यही होगा।</p>
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Paperback</h6>
    <h6><strong>pages:</strong> 192</h6>
    <h5 className=" mt-5"><strong>TAGS</strong></h5>
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} /> */}
    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>
</Container>
    </div>
  )
}

export default Cg