import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import { Link} from 'react-router-dom';
// import Card from 'react-bootstrap/Card';
//import { Chip } from '@mui/material';
import '../style.css';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Zoom from 'react-reveal/Zoom';
function Somebooks() {
  return (
    <div>
<Container>
    <Row className='mt-5'>
        <Col lg={12} md={12} sm={12}>
            <Zoom>
       <h1 className="one" style={{textAlign:'left',color:'#ff8664'}}><i>All Books</i></h1>
       </Zoom>
        </Col>
    </Row>

    <Row className='mt-5'>
    
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/Machhuware.jpg" width={'100%'} height={'350px'} style={{borderRadius:'25px'}}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}> मछुआरे की लड़की</h2>
</Zoom>
<p> By <span>डॉ. विनोद कुमार वर्मा</span></p>
{/* <p> ₹ 999.00 inclusive of all taxes</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<p className=''> मछुआरे की लड़की (2000) राष्ट्रीय स्तर पर चर्चित | प्रकृति अंचल के बड़े केनवास पर उकेरी गई या कहानी <strong>'कथा शिल्प'</strong> की दृष्टिकोण से बेजोड़ है| इसे <strong>"विकलांग-विमर्श की कहानियां"</strong> (2010) अविभाजित मध्यप्रदेश के कथाकार ऊपर केंद्रित कथा को <strong>'कथा मध्य प्रदेश'</strong> (2017) के साथ <strong>'बस्तर  विश्वविद्यालय'</strong> के एम.ए. के पाठ्यक्रम में शामिल किया गया है|.</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/machhuare_ki_ladki' className='link2'>Read More</a></Button>{' '}
    <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Market</h6>
    {/* <h6><strong>pages:</strong> 192</h6> */}
    {/* <h5 className=" mt-5"><strong>TAGS</strong></h5>
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} /> */}
    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/cgfront.jpg" width={'100%'} height={'350px'} style={{borderRadius:'25px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>छत्तीसगढ़ सम्पूर्ण वस्तुनिष्ठ</h2>
</Zoom>
<p> By <span>राहुल वर्मा, जय कुमार सिंह, सागर नायक</span></p>
<h6>About the book</h6>
<p className='cardtext'> छत्तीसगढ़ की प्रतियोगी परीक्षाओं के लिए.</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'><a href='/#/cg_objective' className='link2'>Read More</a> </Button>{' '}
    <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Market</h6>
    {/* <h6><strong>pages:</strong> 192</h6> */}
    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/front 1.jpg" width={'100%'} height={'350px'} style={{borderRadius:'25px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>छत्तीसगढ़ी का सम्पूर्ण व्याकरण </h2>
</Zoom>
<p> By <span>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </span></p>
<h6>About the book</h6>
<p className='cardtext'>छत्तीसगढ़ी व्याकरण की मानक मार्गदर्शिका.</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/cg_ka_vyakaran' className='link2'>Read More</a></Button>{' '}
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='https://www.amazon.in/CHHATTISGARH-SAMPURNA-VYAKARAN-Vinod-Kumar/dp/8193253418/ref=pd_bxgy_img_sccl_1/260-7835383-6341028?pd_rd_w=wodsk&content-id=amzn1.sym.d68c4347-8b80-4998-9474-4671a1e32e96&pf_rd_p=d68c4347-8b80-4998-9474-4671a1e32e96&pf_rd_r=0EM0G56CXNF0P8KF9M92&pd_rd_wg=A2TIR&pd_rd_r=fa0ae163-5af1-4235-beab-7c384132da8a&pd_rd_i=8193253418&psc=1' className='link2'>Purchase Now</a></Button>{' '}
    <h6><strong>pages:</strong> 384</h6>
    <h6><strong>Available in: </strong> Market / Online</h6>
    {/* <h6><strong>pages:</strong> 192</h6> */}
    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/front.jpg" width={'100%'} height={'350px'} style={{borderRadius:'25px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>हिंदी का सम्पूर्ण व्याकरण</h2>
</Zoom>
<p> By <span>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </span></p>
<h6>About the book</h6>
<p className='cardtext'>हिंदी व्याकरण की मानक मार्गदर्शिका.</p>

<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/hindi_wayakran' className='link2'>Read More</a></Button>{' '}
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='//www.amazon.in/SAMPURNA-VYAKARAN-CHHATTISGARH-VISHESH-SANDHARBH/dp/8193253434/ref=sr_1_1?qid=1665650428&refinements=p_27%3AVinod+Kumar+Verma&s=books&sr=1-1' className='link2'>Purchase Now</a></Button>{' '}
   

    <h6><strong>pages:</strong> 438</h6>
    <h6><strong>Available in: </strong> Market / Online</h6>
    {/* <h6><strong>pages:</strong> 192</h6> */}
    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/vimarsh.jpg" width={'100%'} height={'350px'} style={{borderRadius:'25px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>विमर्श के निष्कर्ष पर छत्तीसगढ़ी</h2>
</Zoom>
<p> By <span>डॉ. विनोद कुमार वर्मा</span></p>
<h6>About the book</h6>
<p className='cardtext'> छत्तीसगढ़ी भाषा की अनुसंधानपरक पर विवेचना .</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/vimarsh_ke_nishkarsh' className='link2'>Read More</a></Button>{' '}
    <h6><strong>pages:</strong> 199</h6>
    <h6><strong>Available in: </strong> Market</h6>
    {/* <h6><strong>pages:</strong> 199</h6> */}
    </Col>
    </Row>

    <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/cgexplain.jpeg" width={'100%'} height={'350px'} style={{borderRadius:'25px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
    <Zoom>
<h2 className=" " style={{textAlign:'left'}}>छत्तीसगढ़ सम्पूर्ण वस्तुनिष्ठ( व्याख्यात्मक स्वरूप में ) </h2>
</Zoom>
<p> By <span>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</span></p>
<h6>About the book</h6>
<p className='cardtext'> प्रतिस्पर्धा के इस दौर में युवाओं के समक्ष रोजगार के अनेक अवसर विद्यमान हैं। युवाओं को रोजगार के लिए विदेशों की ओर उन्मुख होना स्पष्ट रूप से प्रतिभा का पलायन है परन्तु वैश्वीकरण के इस युग में इसे रोका भी नहीं जा सकता। यदि स्थानीय स्तर पर देखें तो प्रत्येक राज्यों की सिविल सेवा परीक्षा होती है जो सबसे प्रतिष्ठित और निर्णायक मानी जाती है।</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/cgobjective_explain' className='link2'>Read More</a></Button>{' '}
    <h6><strong>pages:</strong> 699</h6>
    <h6><strong>Available in: </strong> Market</h6>
    {/* <h6><strong>pages:</strong> 199</h6> */}
    </Col>
    </Row>

    {/* <Row className='mt-5'>
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/cg_margdarshika.jpg" width={'100%'} height={'350px'} style={{borderRadius:'25px'}}/>
    </Col>
    <Col lg={1} md={1}> </Col>  
    <Col sm={12} md={7} className='text-BD'> 
<h2 className=" " style={{textAlign:'left'}}>छत्तीसगढी का  मानकीकरण : मार्गदर्शिका  </h2>
<p> By <span> डॉ. विनोद कुमार वर्मा ( मुख्य संपादक एवं संयोजक संगोष्ठी )</span></p>
<h6>About the book</h6>
<p className='cardtext'> छत्तीसगढी के मानकीकरण के लिए 22 जुलाई 2018 को बिलासपुर में आयोजित राज्यस्तरीय संगोष्ठी में राज्य के विभिन्न जिलों से आये शताधिक साहित्यकारों ने भाग लिया। छत्तीसगढ राजभाषा आयोग के अध्यक्ष डाॅ विनय कुमार पाठक के मुख्य आतिथ्य एवं पद्मश्री डाॅ सुरेन्द्र दुबे ( सचिव, छत्तीसगढ राजभाषा आयोग ) की अध्यक्षता में आयोजित इस संगोष्ठी में छत्तीसगढी के मानकीकरण के लिए अत्यन्त महत्वपूर्ण निर्णय लिए गये। यह संगोष्ठी मुख्य रूप से ' छत्तीसगढी भाषा और देवनागरी लिपि ' एवं ' छत्तीसगढी गद्य साहित्य में अपभ्रंश लेखन का दुष्प्रभाव ' पर केन्द्रित था।</p>
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='/#/cg_ka_mankikaran' className='link2'>Read More</a></Button>{' '}
    <h6><strong>pages:</strong> 699</h6>
    <h6><strong>Available in: </strong> Market</h6>
    <h6><strong>pages:</strong> 199</h6>
    </Col>
    </Row> */}

</Container>
    </div>
  )
}

export default Somebooks