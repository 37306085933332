import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Card from 'react-bootstrap/Card';
import { Link} from 'react-router-dom';
// import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import '../style.css';
import Zoom from 'react-reveal/Zoom';
//import Box from '@mui/material/Box';
function Bestselling() {

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 3000,
  //   autoplaySpeed: 500,
  //   cssEase: "linear",
   

  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //          infinite: false,
  //         dots: true,
  //       }
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //         infinite: false,
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: false,
  //       }
  //     }
  //   ]

  // };
  return (
    <div>
<Container className='mt-5 mb-5'>
<Row>
    <Col md={4}>
    <Zoom>
    <h1 className='heading1'>Bestselling Books </h1>
    </Zoom>
    </Col>
    <Col><Divider  className='divider mt-4 d-none d-sm-block'  style={{}} /></Col>
    <Col md={3}>
    <Zoom>
      <Button variant="danger" className='c-btn '> <a href='/#/Books' className='link2'>View All</a></Button>{' '}
      </Zoom>
    </Col>
</Row>
</Container>

{/* slider start */}
{/* <Container className='mb-5'>
<Row className='mx-2'>
  <Col lg={12} md={12} sm={12}>
<div>
       
        <Slider {...settings}>
          <div>
          <Link to="/Books"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./images/front 1.jpg" /></Link>
      <Card.Body>
      <Link to="/Books" className='link'> <Card.Title className='mb-2 slider-text'>छत्तीसगढ़ी का संपूर्ण व्याकरण </Card.Title> </Link>
       <Card.Text className='mb-1'>
       <Link to="/Authors" className='link mx-2'>   <strong style={{ marginLeft:'35px'}}> डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</strong> </Link><br/>
     
        </Card.Text>
      </Card.Body>
          </div>
          <div >
          <Link to="/Books"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./images/front.jpg" /></Link>
      <Card.Body>
      <Link to="/Books" className='link'> <Card.Title className='mb-2 slider-text'>हिंदी का संपूर्ण व्याकरण</Card.Title> </Link>
       <Card.Text className='mb-1'>
       <Link to="/Authors" className='link mx-2'>   <strong style={{ marginLeft:'35px'}}>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </strong> </Link><br/>
      
        </Card.Text>
      </Card.Body>
          </div>
          <div>
          <Link to="/Books"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3 ' src="./images/cgfront.jpg" /></Link>
      <Card.Body>
      <Link to="/Books" className='link'> <Card.Title className='mb-2 slider-text'>छत्तीसगढ़ संपूर्ण वस्तुनिष्ठ</Card.Title> </Link>
       <Card.Text className='mb-1'>
       <Link to="/Authors" className='link mx-2'>   <strong style={{ marginLeft:'35px'}}> राहुल वर्मा, जय कुमार सिंह, सागर नायक</strong> </Link><br/>
 
        </Card.Text>
      </Card.Body>
          </div>
          <div >
          <Link to="/Books"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./images/Machhuware.jpg" /></Link>
      <Card.Body>
      <Link to="/Books" className='link'> <Card.Title className='mb-2 slider-text '>मछुआरे की लड़की</Card.Title> </Link>
       <Card.Text className='mb-1'>
       <Link to="/Authors" className='link mx-2'>   <strong style={{ marginLeft:'35px'}}> डॉ. विनोद कुमार वर्मा</strong> </Link><br/>
  
        </Card.Text>
      </Card.Body>
          </div>
   
        </Slider>
      </div>
      </Col>
</Row>
</Container> */}
{/* slider end */}

<Container className='mb-5 bestselling'>
<Row className='mx-2'>
  <Col lg={4} md={4} sm={12}>
  <div >
          <Link to="/hindi_wayakran"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./images/front.jpg" /></Link>
      <Card.Body>
      <Link to="/hindi_wayakran" className='link'> <Card.Title className='mb-2 slider-text'>हिंदी का सम्पूर्ण व्याकरण</Card.Title> </Link>
       <Card.Text className='mb-1'>
       <Link to="/Authors" className='link mx-2'>   <strong style={{ marginLeft:'20px'}}>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा </strong> </Link><br/>
      
        </Card.Text>
      </Card.Body>
          </div>
  </Col>

  <Col lg={4} md={4} sm={12}>
  <div>
          <Link to="/machhuare_ki_ladki"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3 ' src="./images/Machhuware.jpg" /></Link>
      <Card.Body>
      <Link to="/machhuare_ki_ladki" className='link'> <Card.Title className='mb-2 slider-text'>मछुआरे की लड़की</Card.Title> </Link>
       <Card.Text className='mb-1'>
       <Link to="/Authors" className='link mx-2'>   <strong style={{ marginLeft:'35px'}}> डॉ. विनोद कुमार वर्मा</strong> </Link><br/>
 
        </Card.Text>
      </Card.Body>
          </div>
  </Col>

  <Col lg={4} md={4} sm={12}>
  <div >
          <Link to="/cg_ka_vyakaran"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./images/front 1.jpg" /></Link>
      <Card.Body>
      <Link to="/cg_ka_vyakaran" className='link'> <Card.Title className='mb-2 slider-text '>छत्तीसगढ़ी का सम्पूर्ण व्याकरण</Card.Title> </Link>
       <Card.Text className='mb-1'>
       <Link to="/Authors" className='link mx-2'>   <strong style={{ marginLeft:'20px'}}> डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</strong> </Link><br/>
  
        </Card.Text>
      </Card.Body>
          </div>
  </Col>
 
</Row>
</Container>

    </div>
  )
}

export default Bestselling


