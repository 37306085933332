import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.css';
import Zoom from 'react-reveal/Zoom';
function AboutUs() {
  return (
    <div>

<Container>
<Row className='mt-5'>
        <Col lg={12} md={12} sm={12}>
            <Zoom>
       <h1 className="one" style={{textAlign:'left',color:'#ff8664'}}><i>Vadanya Publication</i></h1>
       </Zoom>
        </Col>
    </Row>

<Row>
    <Col sm={12} md={12}>
      {/* <Zoom>
    <h2 className=" mt-5 text-center" style={{color: '#0E204D'}}><strong>Vadanya Publication</strong></h2>
    </Zoom> */}
    <Zoom>
    <p className="mt-5 mb-5 text-justify" style={{textAlign:'justify'}}>  शोध आधारित ग्रंथों/पुस्तकों का लेखन कठिन और चुनौतीपूर्ण होता है क्योंकि इसके लेखन में- सत्यता को परखने के लिए बहुत-सारे संदर्भ ग्रंथों की जरूरत पड़ती है, कभी-कभी यह शताधिक होती है। विद्या के अर्थ को ग्रहण करने वालों को विद्यार्थी कहा जाता है। विद्या की देवी <strong>सरस्वती</strong> है।  वस्तुतः ' वदान्या पब्लिकेशन ' विद्यार्थियों या सरस्वती उपासकों का प्रकाशन है, जो ज्ञान की गंगा से आप्लावित अंतःसलिला सदृश है। अंतःसलिला वह नदी होती है जिसकी धारा भूमि के अंदर ही अंदर बहती है और उपर दिखाई नहीं देती।<br/><br/>
    किसी भी ग्रंथ/पुस्तक में संयोजित पठनीय सामाग्री को गागर में एकत्रित जल की संज्ञा भी दी जा सकती है परन्तु वह जल कैसा हो- उसका उपयोग कैसे किया जावेगा,  इसका अपना अलग महत्व है। गागर में एकत्रित गंगोत्री के शुद्ध व पवित्र जल का महत्व भलेहि सरोवर या सागर के जल से भिन्न है परन्तु उससे कम नहीं है क्योंकि यह हमारी अक्षुण्य सांस्कृतिक यात्रा व धरोहर का प्रतीक है। वस्तुतः गागर में भरे हुए अमृतमयी जल के लिए ही  ' गागर में सागर ' की संज्ञा सृजित हुई है। <br/><br/>
    संप्रति, वदान्या पब्लिकेशन द्वारा प्रकाशित मानक ग्रंथों/पुस्तकों का आकलन आप स्वयं करें कि उन पुस्तकों में संयोजित पठनीय सामाग्री किस स्तर की है- वह शोध संदर्भों से युक्त व्याख्यात्मक स्वरूप में है या नहीं?......अथवा वह अमृतमयी जल के समान है या कुछ और ही है ?....... वस्तुतः विश्वविद्यालयों में अध्ययनरत विद्यार्थियों, शोधार्थियों के साथ हिन्दी के प्रति जिज्ञासा रखने वाले हिन्दी प्रेमी पाठकों के लिए ज्ञानवर्धक, शोधपूर्ण और उपयोगी  जानकारी देना ही वदान्या पब्लिकेशन का अंतिम लक्ष्य है।

    </p>
    </Zoom>
    </Col>
</Row>
</Container>

    </div>
  )
}

export default AboutUs