import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Link from '@mui/material/Link';
//import Divider from '@mui/material/Divider';

function Footer() {
  return (
    <div>

<Container className="mt-5" fluid style={{backgroundColor:'#F6F0BC'}}>
  <Container>
    <Row>
      <Col lg={3} md={3}>
      <h5 className="font-weight-bold  footer_heading mt-5 mb-4" style={{ display: 'flex'}}><img
              alt=""
              src="../images/logovadanya.png"
              width="150"
              height="80"
              className="d-inline-block align-top"
            />{' '}</h5>
   <p style={{ color: 'black'}}>  वदान्या पब्लिकेशन ' विद्यार्थियों या सरस्वती उपासकों का प्रकाशन है, जो ज्ञान की गंगा से आप्लावित अंतःसलिला सदृश है।</p>
      </Col>
 <Col lg={1} md={1}>  <div className='vertical_line d-none d-md-block d-lg-block'></div></Col>  
      <Col  lg={8} md={7}>
        <Row>
      <Col lg={4} md={3}>
      <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>Popular Books</h5>
      <ul className="list-unstyled">
          <li>
          <Link href="/#/hindi_wayakran" className="footer_list_item" underline="hover" style={{ display: 'flex',color:'black'}}>{'हिंदी का सम्पूर्ण व्याकरण'}</Link>
          
          </li>
          <li>
          <Link href="/#/cg_ka_vyakaran" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'black'}}>{'छत्तीसगढ़ी का सम्पूर्ण व्याकरण'}</Link>
           
          </li>
          <li>
          <Link href="/#/cg_objective" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'black'}}>{'छत्तीसगढ़ सम्पूर्ण वस्तुनिष्ठ'}</Link>
       
          </li>
          <li>
          <Link href="/#/machhuare_ki_ladki" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'black'}}>{'मछुआरे की लड़की'}</Link>
          
          </li>
          <li>
          <Link href="/#/vimarsh_ke_nishkarsh" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'black'}}>{'विमर्श के निष्कर्ष पर छत्तीसगढ़ी'}</Link>
          
          </li>
        </ul>
      </Col>
      <Col lg={4} md={3}>
      <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>Popular Authors</h5>
      <ul className="list-unstyled">
          <li>
          <Link href="/#/vinod_pathak_ji" underline="hover" style={{ display: 'flex',color:'black'}}>{'डॉ. विनय कुमार पाठक'}</Link>
          
          </li>
          <li>
          <Link href="http://3tinfosystems.com/" underline="hover" style={{ display: 'flex',color:'black'}}>{'डॉ. विनोद कुमार वर्मा'}</Link>
           
          </li>
          {/* <li>
          <Link href="/#/AuthorDetails" underline="hover" style={{ display: 'flex',color:'black'}}>{'जय कुमार सिंह'}</Link>
       
          </li>
          <li>
          <Link href="/#/rahulverma" underline="hover" style={{ display: 'flex',color:'black'}}>{'राहुल वर्मा'}</Link>
          
          </li>
          <li>
          <Link href="/#/sagarnayak" underline="hover" style={{ display: 'flex',color:'black'}}>{'सागर नायक'}</Link>
          
          </li> */}
        </ul>
      </Col>
      <Col lg={4} md={3}>
      <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>Contact Us</h5>
      <ul className="list-unstyled mb-5">
          <li>
          <Link href="#" underline="hover" style={{ display: 'flex',color:'black'}}>{'वदान्या प्रकाशन :'}</Link>
          
          </li>
          <li>
          <Link href="#" underline="hover" style={{ display: 'flex',color:'black'}}>{'एम. आई. जी. - 59 , नेहरू नगर ,'}</Link>
           
          </li>
          <li>
          <Link href="#" underline="hover" style={{ display: 'flex',color:'black'}}>{'बिलासपुर , छत्तीसगढ़'}</Link>
       
          </li>
          <li>
          <Link href="#" underline="hover" style={{ display: 'flex',color:'black'}}>{'Mo. :- 98266-77946'}</Link>
          
          </li>
          {/* <li>
          <Link href="#" underline="hover" style={{ display: 'flex',color:'black'}}>{'   :- 77710-30030'}</Link>
          
          </li> */}
        </ul>
      </Col>
      </Row>
      </Col>
    </Row>
  </Container>
  <Container>
    <Row>
    <footer class="text-center text-lg-start  text-muted">
  {/* <!-- Section: Social media --> */}
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    {/* <!-- Left --> */}
    <div class="me-5 d-none d-lg-block">
      <span style={{color:'black'}}>All Rights Reserved <strong className='link'>Vadanya Publication </strong> © 2022 | Designed & Developed By <Link href="https://www.primusinfosystems.com/" target="_blank" underline="hover" style={{ color:'black'}}><strong className='link'>Primus Info System.</strong></Link> </span>
    </div>
    {/* <!-- Left --> */}

    {/* <!-- Right --> */}
    <div>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-google"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-github"></i>
      </a>
    </div>
    {/* <!-- Right --> */}
  </section>
  {/* <!-- Section: Social media --> */}
  </footer>
    </Row>
  </Container>
</Container>

    </div>
  )
}

export default Footer