import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
// import { Chip } from '@mui/material';
function Cgvayakaran() {
  return (
    <div>
<Container>
  <Row className="mt-5 mb-5 ">
    <Col sm={12} md={4}>
    <Image className="bookdetail-img" src="../images/front 1.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
   
    </Col>
    <Col sm={12} md={8} className='text-BD'> 
<h2 className=" " style={{textAlign:'left'}}>छत्तीसगढ़ी का सम्पूर्ण व्याकरण</h2>
<p> By <span>डॉ. विनय कुमार पाठक, डॉ. विनोद कुमार वर्मा</span></p>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}
<Button variant="danger" className='c-btn-without-arrow mb-3'> <a href='https://www.amazon.in/CHHATTISGARH-SAMPURNA-VYAKARAN-Vinod-Kumar/dp/8193253418/ref=pd_bxgy_img_sccl_1/260-7835383-6341028?pd_rd_w=wodsk&content-id=amzn1.sym.d68c4347-8b80-4998-9474-4671a1e32e96&pf_rd_p=d68c4347-8b80-4998-9474-4671a1e32e96&pf_rd_r=0EM0G56CXNF0P8KF9M92&pd_rd_wg=A2TIR&pd_rd_r=fa0ae163-5af1-4235-beab-7c384132da8a&pd_rd_i=8193253418&psc=1' className='link2'>Purchase Now</a></Button>{' '}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<p style={{textAlign:'justify'}}> नौ खण्डों में 46 अध्यायों को समेटे <strong>' छत्तीसगढी का सम्पूर्ण व्याकरण '</strong>  ( पृ 384 ) छत्तीसगढ राजभाषा आयोग के सहयोग से 2018 के उत्तरार्ध में प्रकाशित अकादमिक दृष्टिकोण से एक महत्वपूर्ण मानक ग्रंथ है। इस ग्रंथ के लेखक सुप्रसिद्ध भाषाविद् <strong>डाॅ. विनय कुमार पाठक</strong> और <strong>डाॅ. विनोद कुमार वर्मा</strong> हैं।</p>
  
  <p style={{textAlign:'justify'}}> <strong>लेखकीय अभिमत :- </strong> भाषा और मंदिर के कपाट को कभी भी बन्द नहीं रखना चाहिए,  उसका खुला रहना सुखकर और समृद्धकर होता है। बिना किसी भ्रम के भावों की अभिव्यक्ति का माध्यम तो भाषा ही है जिसमें बिना स्वीकार्यता के अपभ्रंश लेखन आत्मघाती है। छत्तीसगढी भाषा आज इसी आत्मघाती दौर से गुजर रहा है।<br/>
  छत्तीसगढी भाषा को अपभ्रंश लेखन की संक्रमणकालीन संस्थित से मुक्त कराने व आधुनिक युग की माँग की अवस्थित के अनुरूप यह नव्य व्याकरण ग्रंथ प्रस्तुत है। नामानुरूप यह ग्रंथ संपूर्णता को भी संसूचित करता है क्योंकि इसके पूर्व किसी भी छत्तीसगढ व्याकरण में रस, छंद, अलंकार आदि की सम्यक् विवेचना समाहित नहीं थी और न ही आधुनिकता का आग्रह था, संप्रति आज शिक्षा के प्रचार-प्रसार के पश्चात छत्तीसगढी ने देवनागरी के समग्र वर्णों को अपना लिया है और छत्तीसगढी बोलने वाले उच्चारण भी तद्वत् करने में सक्षम-समर्थ हैं।
  </p>
  
    {/* <h6><strong>pages:</strong> 192</h6>
    <h6><strong>Available in: </strong> Paperback</h6>
    <h6><strong>pages:</strong> 192</h6>
    <h5 className=" mt-5"><strong>TAGS</strong></h5>
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} />
    <Chip label="Clickable" onClick={{}} /> */}
    {/* <Chip label="Simple Basic Chip" /> */}

    </Col>
    </Row>
</Container>

<Container>
    <Row>
        <Col sm={12} md={12} lg={12}>
        <p style={{textAlign:'justify',marginTop:'-30px'}}>' श ' और ' ष ' के स्थान में केवल ' स ' के प्रयोग कर देने पर विसंगति का वृत विनिर्मित हो रहा है। जैसे ' शंकर ' को ' संकर ' लिख देने से या ' शेष ' को ' सेस ' लिख देने से अर्थ-द्योतिक नहीं होता। व्यक्तिवाचक संज्ञाओं का रूपांकन - संतोष ( Santosh ) को संतोस ( Santos ),  ज्ञानु ( Gaynu ) को ग्यानु ( Gyanu),  लक्ष्मी ( Lakshmi ) को लक्छमी ( Lakchhami ) वर्तमान परिपेक्ष्य में स्वीकार्य नहीं हो सकता। <br/>
            संप्रति, <strong>यह ग्रंथ छत्तीसगढी भाषा हेतु हिन्दी के लिए अंगीकृत देवनागरी लिपि की समस्त 52 वर्णों के स्वीकार्यता की पीठिका भी है। </strong> पूर्णता की दावा करना नासमझी होगी, लेकिन अब तक हुए कार्यों के बाद नये कलेवर व तेवर के साथ भाषायी विकास और आधुनिक उद्भाष के निष्कर्ष पर यह ग्रंथ आपके सम्मुख है।</p>
            <h4 style={{color:'#F65D4E'}}><strong>पुस्तक एक नजर में :</strong> Book at a glance</h4>
<p style={{textAlign:'justify'}}> खण्ड ( 1 ) में भाषा के वैश्विक व राष्ट्रीय संदर्भों को रेखाँकित करते हुए छत्तीसगढी भाषा का विकासक्रम,  छत्तीसगढी भाषा और देवनागरी लिपि के साथ खण्ड ( 2 ) में छत्तीसगढ की प्रमुख बोलियों पर चर्चा की गई है।<br/>
खण्ड ( 3 ) से खण्ड ( 6 ) तक छत्तीसगढी व्याकरण के अन्तर्गत शब्द-साधन व शब्द-रचना की विभिन्न विधियाँ, रस, छंद, अलंकार, हाना, मुहावरा, जनउला एवं खण्ड ( 9 ) में विलोम शब्द, अनेक शब्दों के लिए एक शब्द,  तत्सम्,  तद्भव, देशज्,  विदेशज् व संकर शब्द, पर्यायवाची आदि को सरल व सुगम-सुबोध भाषा-शैली में विवेचित किया गया है। खण्ड ( 7 ) में छत्तीसगढ राजभाषा आयोग और छत्तीसगढी के प्रमुख साहित्यकारों पर चर्चा की गई है। <br/>
इस ग्रंथ की एक खास विशेषता भी है- लोक व्यवहार में छत्तीसगढी ( अध्याय 38 ), पाद टिप्पणी ( अध्याय 39 ), महत्वपूर्ण शब्दावली ( अध्याय 43 ), संख्यायें ( अध्याय 44 ), प्रशासनिक शब्दकोश ( अध्याय 46 ) को तीन भाषाओं <strong>छत्तीसगढी,हिन्दी </strong>और <strong>अंग्रेजी</strong> में दिया गया है ताकि इसे अंग्रेजी माध्यम से शिक्षा ग्रहण करने वाले विद्यार्थी भी आसानी से समझ सकें व पठन-पाठन कर सकें।
</p>
       
       {/* <h4>पाठकीय अभिमत </h4> */}
       {/* <p>   इस ग्रंथ के बारे में ढेर सारी पाठकीय प्रतिक्रियायें अमेजान आदि में पढ़ने को मिल रही है जिसमें से केवल एक-दो का उल्लेख संक्षेप में सांकेतिक रूप में दिया जा रहा है-</p>
       <p style={{textAlign:'justify'}}> <strong>अनुपम गुप्ता</strong>  ने 25 फरवरी 2021 को लिखा है- ' यह पुस्तक स्नातक और स्नातकोत्तर छात्रों के लिए अत्यन्त ज्ञानवर्धक और उपयोगी है जो हिन्दी भाषा में विशिष्ट स्तर की विशेषज्ञता हासिल कर रहे हैं। इसके अलावा यह उन छात्रों के लिए काफी उपयोगी है जो प्रतियोगी परीक्षाओं की तैयारी कर रहे हैं। हिन्दी व्याकरण की यह पुस्तक अद्भूत और दिलचस्प है। '</p>
       <p style={{textAlign:'justify'}}> <strong>श्रीजानी डी</strong>  ने 06 मार्च 2022 को लिखा है- ' यह अकादमिक दृष्टिकोण से अत्यन्त महत्वपूर्ण ग्रंथ है।  इसमें भाषा के विकास के साथ व्याकरण संबंधी गूढ़ बातों को तथ्यों के साथ दर्शाया गया है।  मुझे पुरा विश्वास है कि यह ग्रंथ देश-विदेश में रह रहे हर वर्ग के लोगों के लिए बहूपयोगी एवं मार्गदर्शक साबित होगा एवं अभी तक लिखे गए हिन्दी व्याकरण ग्रंथों में महत्वपूर्ण स्थान हासिल करेगा। '</p>
        */}
        </Col>
    </Row>
</Container>

    </div>
  )
}

export default Cgvayakaran