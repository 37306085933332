import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import {HashRouter,Routes,Route} from 'react-router-dom';
import Books from './pages/Books';
import Contact from './pages/Contact';
import About from './pages/About';
import Authors from './pages/Authors';
import AuthorDetails from './pages/AuthorDetails';
import Rahulverma from './components/Rahulverma';
import Homepage from './pages/Homepage';
import Sagarnayak from './components/Sagarnayak';
import Hindi from './components/Hindi';
import Cg from './components/Cg';
import Machhuaare from './components/Machhuaare';
import Vimarsh from './components/Vimarsh';
import Cgvayakaran from './components/Cgvayakaran';
import Vinodpathak from './components/vinodpathak';
import Cgobjective from './components/Cgobjective';
import Cgmankikaran from './components/Cgmankikaran';
import Scroll from './components/Scroll';
import mail from './mail.php';
function App() {
  return (
    <HashRouter>
    <div className="App">
    <Scroll />
    <Navbar/>
    <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/Books' element={<Books />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/Authors' element={<Authors />} />
          <Route path='/AuthorDetails' element={<AuthorDetails />} />
          <Route path='/rahulverma' element={<Rahulverma />} />
          <Route path='/sagarnayak' element={<Sagarnayak />} />
          <Route path='/hindi_wayakran' element={<Hindi />} />
          <Route path='/cg_objective' element={<Cg />} />
          <Route path='/machhuare_ki_ladki' element={<Machhuaare />} />
          <Route path='/vimarsh_ke_nishkarsh' element={<Vimarsh />} />
          <Route path='/cg_ka_vyakaran' element={<Cgvayakaran />} />
          <Route path='/vinod_pathak_ji' element={<Vinodpathak />} />
          <Route path='/cgobjective_explain' element={<Cgobjective />} />
          <Route path='/cg_ka_mankikaran' element={<Cgmankikaran />} />
          <Route path='/mail' element={mail} />
        </Routes>
    <Footer/>
    </div>
    </HashRouter>
  );
}

export default App;
